import React from 'react'
import styles from './social.module.css'

import iconFB from '../../assets/detailmenu/Messenger-min.png';
import iconWapp from '../../assets/detailmenu/whatsapp-min.png';
import iconphone from '../../assets/detailmenu/phone-min.png';

export default function Social() {
  return (
    <div className={styles.socialContainer}>
      <div className={styles.imgicon}>
        <a href="https://www.facebook.com/messages/t/RestauranteLaiLai" target="blank">
          <img src={iconFB} alt="logo" />
        </a>
      </div>
      <div className={styles.imgicon}>
        <a href="https://api.whatsapp.com/send?phone=50247931733" target="blank">
          <img src={iconWapp} alt="logo" />
        </a>
      </div>
      <div className={styles.imgicon}>
        <a href="tel:1733">
          <img src={iconphone} alt="logo" />
        </a>
      </div>
    </div>
  )
}
