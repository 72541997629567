import React from 'react';
import styles from './menu.module.css';
//import menu1 from '../../assets/menu/1.jpg';
//import menu2 from '../../assets/menu/2.jpg';
//import menu3 from '../../assets/menu/1.jpg';
//import logo from '../../assets/home/menu_1.png';
import platillofuerte from '../../assets/MenuIMG/Platos_Fuertes/CHOP SUEY, SAN CHOY PAU _ CHAW SAM SI/SANCHOYCA_MENU-normal.png';
import sopas from '../../assets/home/Artboard_2-100-min.jpg';
import entradas from '../../assets/home/Entradas.jpg';
import postres from '../../assets/banners/CORBADUL_MENU_RESPONSIVE.jpg'
import sopasicon from '../../assets/menu/sopa-min.png';
import entradasicon from '../../assets/menu/entrada-min.png';
import platillosicon from '../../assets/menu/platillos-min.png';

import Carousel, { slidesToShowPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';


class Menu extends React.Component {
  constructor() {
    super()
    this.state = {
      value: 0,
      slides: [
        (
          <div className={styles.carContainer}>
            <a className={`${styles.button}`} href="/Menu#entradas">
              <img src={entradas} className={`${styles.itemimg}`} alt="item" />
              <img src={entradasicon} className={`${styles.iconitem}`} alt="item" />
              <p className={`${styles.textitem}`}>Entradas</p>
              <p className={`${styles.textitembottom}`}>Conoce Nuestras Entradas</p>
            </a>
          </div>
        ),
        (
          <div className={styles.carContainer}>
            <a className={`${styles.button}`} href="/Menu#especialidades">
              <img src={platillofuerte} className={`${styles.itemimgGRANDE}`} alt="item" />
              <img src={platillosicon} className={`${styles.iconitem}`} alt="item" />
              <p className={`${styles.textitem}`}>Platillos Fuertes</p>
              <p className={`${styles.textitembottom}`}>Conoce Nuestros Platillos</p>
            </a>
          </div>
        ),
        (
          <div className={styles.carContainer}>
            <a className={`${styles.button}`} href="/Menu#sopas_mein">
              <img src={sopas} className={`${styles.itemimg}`} alt="item" />
              <img src={sopasicon} className={`${styles.iconitem}`} alt="item" />
              <p className={`${styles.textitem}`}>Sopas</p>
              <p className={`${styles.textitembottom}`}>Conoce Nuestras Sopas</p>
            </a>
          </div>),
        (
          <div className={styles.carContainer}>
            <a className={`${styles.button}`} href="/Menu/postres_bebidas">
              <img src={postres} className={`${styles.itemimg}`} alt="item" />
              <img src={sopasicon} className={`${styles.iconitem}`} alt="item" />
              <p className={`${styles.textitem}`}>Postres y Bebidas</p>
              <p className={`${styles.textitembottom}`}>Conoce Nuestros Postres y Bebidas</p>
            </a>
          </div>),


      ],
    }
    this.onchange = this.onchange.bind(this);
  }

  componentDidMount() {
    console.clear()
  }

  componentDidUpdate() {
    console.clear()
  }

  onchange(value) {
    this.setState({ value });
  }
  render() {
    return (
      <div className={`align-items-center ${styles.background}`}>
        <div className="row">
          <div className={`col col-sm col-lg ${styles.title}`}>
            <p>Menú LAI LAI</p>
          </div>
        </div>
        <div className="row">
          <div className={`col col-sm col-lg ${styles.containercarousel}`}>
            <div className={styles.carDiv}>
              <Carousel
                plugins={[
                  {
                    resolve: slidesToShowPlugin,
                    options: {
                      numberOfSlides: 1.2
                    }
                  },

                  'fastSwipe',

                ]}
                value={this.state.value}
                slides={this.state.slides}
                onChange={this.onchange}
                className={styles.Carousel}
              />
            </div>
            <div className={styles.carDivWeb}>
              <div className={`row ${styles.rowimg}`}>
                <div className={`col col-sm col-lg  ${styles.Divareaimgcenterleft}`}>
                  <a href="/Menu#entradas">
                    <img src={entradas} alt="item" className={`${styles.Divimgcenter}`} />
                    <img src={entradasicon} className={`${styles.iconitemright}`} alt="item" />
                    <p className={`${styles.textitemright}`}>Entradas</p>
                    <p className={`${styles.textitembottomright}`}>Conoce Nuestras Entradas</p>
                  </a>
                </div>
                <div className={`col col-sm col-lg ${styles.Divareaimgcenter}`}>
                  <a href="/Menu#especialidades">
                    <img src={platillofuerte} alt="item" className={`${styles.Divimgcenter}`} />
                    <img src={platillosicon} className={`${styles.iconitem}`} alt="item" />
                    <p className={`${styles.textitem}`}>Platos Fuertes</p>
                    <p className={`${styles.textitembottom}`}>Conoce Nuestros Platos Fuertes</p>
                  </a>
                </div>
                <div className={`col col-sm col-lg ${styles.Divareaimgcenterright}`}>
                  <a href="/Menu#sopas_mein">
                    <img src={sopas} alt="item" className={`${styles.Divimgcenter}`} />
                    <img src={sopasicon} className={`${styles.iconitem}`} alt="item" />
                    <p className={`${styles.textitem}`}>Sopas</p>
                    <p className={`${styles.textitembottom}`}>Conoce Nuestras Sopas</p>
                  </a>
                </div>
                <div className={`col col-sm col-lg ${styles.Divareaimgcenterright}`}>
                  <a href="/Menu/postres_bebidas">
                    <img src={postres} alt="item" className={`${styles.Divimgcenter}`} />
                    <img src={sopasicon} className={`${styles.iconitem}`} alt="item" />
                    <p className={`${styles.textitem}`}>Postres y Bebidas</p>
                    <p className={`${styles.textitembottom}`}>Conoce Nuestros Postres y Bebidas</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Menu;