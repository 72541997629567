
import React, {Component} from "react";
import {
  Switch,
  withRouter,
  Route,
  Redirect
} from "react-router-dom";
import styles from "./app.module.css";
import Home from "../Home";
import NavBar from "../Navbar";
import Menu from "../Menu";
import About from "../About";
import MenuDetail from "../Menudetail";
import Footer from "../Footer";
import Promotions from "../Promotion";
import Maps from "../Map";
import Reviews from "../Reviews";
import PromotionPage from "../PromotionPage";
import MenuList from "../MenuList";
import StarMenu from '../StarMenu';
import Social from '../Social'

const ContentCore = ({
  location: {
    pathname,
  }
}) => (
  <div>
    <Home language={pathname} />
    <Menu language={pathname} />
    <Promotions language={pathname} />
    <StarMenu language={pathname} />
    <Reviews language={pathname} />
    <Social />
  </div>
);

const ContentPromotion = ({
  location: {
    pathname,
  }
}) => (
  <PromotionPage language={pathname} />

);

const Content = withRouter(ContentCore);
const AboutUS = withRouter(About);
const Menudetail = withRouter(MenuDetail);
const Map = withRouter(Maps);
const Review = withRouter(Reviews);
const PromotionsPage = withRouter(ContentPromotion);
const MenuLists = withRouter(MenuList);
const Navbar = withRouter(NavBar);
const Footers = withRouter(Footer)

class App extends Component  {

constructor(){
  super();
  this.state = {
    data: true
  }
}
componentDidMount(){
  fetch('https://api-ecommerce.devpackgroup.com/api/v1/landing_pages/?page_id=1')
    .then(res => res.json())
    .then(json => {
      if (!json) {
        this.setState({
          data: false
        })
      }
    }  
    )
  }
  render(){
  if (this.state.data === true)
    return (
      <div className="container-fluid">
        <div className={`row ${styles.AbsoluteRow}`}>
          <div className="col-12 col-md">
            <Navbar />
          </div>
        </div>
        <Switch>
          <Route exact path="/Inicio" component={() => <Content />} />
          <Route exact path="/Nosotros" component={() => <AboutUS />} />
          <Route exact path="/DetalleMenu" component={() => <Menudetail />} />
          <Route exact path="/Mapa" component={() => <Map />} />
          <Route exact path="/Comentarios" component={() => <Review />} />
          <Route exact path="/Promociones" component={() => <PromotionsPage />} />
          <Route exact path="/Menu" component={() => <MenuLists />} />
          <Route exact path="/Menu/postres_bebidas" component={() => <MenuLists />} />
          <Redirect to="/Inicio" />
        </Switch>
        <Footers />
      </div>
    )
  else 
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <p>Pagina en mantenimiento...</p>
          </div>
        </div>
      </div>
    )
  }
};

export default withRouter(App);
