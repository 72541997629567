import React from 'react';
import styles from './footer.module.css'
import logo from '../../assets/home/logo_grande.png'
import button from '../../assets/home/boton_1-min.png'
import fb from '../../assets/detailmenu/fb-min.png'
import ig from '../../assets/detailmenu/instagram-min.png'


class Footer extends React.Component {
  render() {
    const {
      location
    } = this.props
    return (
      <>
        {location.pathname === "/Mapa" || location.pathname === "/Menu" ||
        location.pathname === "/Menu/postres_bebidas" ||
          location.pathname === "/Promociones" || location.pathname === "/Nosotros" ?
          <></>
          :
          <>
            <div className={`row ${styles.background} row justify-content-center`}>
              <div className={`col col-sm col-lg ${styles.mobile}`}>
                <div className="row">
                  <div className={`col col-sm col-lg `}>
                    <img src={logo} className={styles.imagelogo} alt="logo" />
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className={`col col-sm col-lg `}>
                    <p className={styles.firsttext} >
                      Para Restaurantes Lai Lai compartir entre amigos o en familia es uno de los mejores placeres que
                      le dan el toque final a todos nuestros platillos haciendo que cada receta...
                      forme parte de la vida de cada persona que vive la experiencia Lai Lai.
                    </p>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className={`col col-sm col-lg `}>
                    <p className={styles.copyright} >
                      2020 Copyrights - <span className={styles.colortextcopyright}>Lai Lai Guatemala</span>
                    </p>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className={`col col-sm col-lg `}>
                    <p className={styles.textreservation} >
                      Visítanos en el siguiente horario:
                    </p>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className={`col col-sm col-lg `}>
                    <p className={styles.schedule} >
                    Lu - Do 10:00 a 22:00
                    </p>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className={`col-2 col-sm-2 col-lg-1 offset-lg-6  offset-sm-3`}>
                    <div className={styles.imgicon}>
                      <a href="https://www.facebook.com/RestauranteLaiLai">
                        <img src={fb} alt="icon" />
                      </a>
                    </div>
                  </div>
                  <div className={`col-2 col-sm-2 col-lg-1 `}>
                    <div className={styles.imgicon}>
                      <a href="https://www.instagram.com/restaurantelailai/">
                        <img src={ig} alt="icon" />
                      </a>
                    </div>
                  </div>
                  <div className={`col-4 col-sm col-lg ${styles.buttonCall}`}>
                    <a href="tel:1733" className={styles.buttonHolder}>
                      <img src={button} alt="logo" className={styles.imgCall} />
                    </a>
                  </div>
                </div>
              </div>
              <div className={`col col-sm col-lg ${styles.desktop}`}>
                <div className="row">
                  <div className={`col col-sm col-lg `}>
                    <img src={logo} className={styles.imagelogo} alt="logo" />
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className={`col col-sm col-lg `}>
                    <p className={styles.firsttext} >
                      Para Restaurantes Lai Lai compartir entre amigos o en familia es uno de los mejores placeres que
                      le dan el toque final a todos nuestros platillos haciendo que cada receta...
                      forme parte de la vida de cada persona que vive la experiencia Lai Lai.
                    </p>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className={`col col-sm col-lg `}>
                    <p className={styles.copyright} >
                      2020 Copyrights - <span className={styles.colortextcopyright}>Lai Lai Guatemala</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className={`col col-sm col-lg ${styles.desktop} `}>
                <div className="row justify-content-center">
                  <div className={`col col-sm col-lg `}>
                    <p className={styles.textreservation} >
                      Visítanos en el siguiente horario:
                    </p>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className={`col col-sm col-lg `}>
                    <p className={styles.schedule} >
                      Lu - Do 10:00 a 22:00
                    </p>
                   
                  </div>
                </div>
                <div className="row ">
                  <div className={`col col-sm-2 col-lg-1 offset-lg-6  offset-sm-3`}>
                    <div className={styles.imgicon}>
                      <a href="https://www.facebook.com/RestauranteLaiLai">
                        <img src={fb} alt="icon" />
                      </a>
                    </div>
                  </div>
                  <div className={`col col-sm-2 col-lg-1 `}>
                    <div className={styles.imgicon}>
                      <a href="https://www.instagram.com/restaurantelailai/">
                        <img src={ig} alt="icon" />
                      </a>
                    </div>
                  </div>
                  <div className={`col col-sm-4 col-lg-2 `}>
                    <a href="tel:1733" className={styles.buttonHolder}>
                      <img src={button} alt="logo" className={styles.imgCall} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      </>
    )
  }
}
export default Footer;

