import React from 'react';
import styles from './navbar.module.css'
import logo from '../../assets/home/logo_small_white.png'
import logorojo from '../../assets/home/logo_rojo.png'
import iconHome from '../../assets/navbar/inicio-min.png'
import iconDomicilio from '../../assets/navbar/a_domicilio-min.png'
import iconPromociones from '../../assets/navbar/promociones-min.png'
import iconmenuDestacado from '../../assets/navbar/menu_destacado-min.png'


class Navbar extends React.Component {

  render() {
    const {
      location
    } = this.props;
    return (
      
      <nav className={`navbar navbar-expand-md navbar-light offset-lg-1 ${styles.navbarHead}`}>
        <div className={styles.hidden}>
          <div className={styles.logo}>
            {
              location.pathname === "/Mapa" || location.pathname === "/DetalleMenu" ?
                <a className="navbar-brand" href="/Inicio"><img src={logorojo} className={styles.logorojo} alt="logo" /> </a>
                :
                <a className="navbar-brand" href="/Inicio"><img src={logo} className={styles.logoblanco} alt="logo" /> </a>
            }
            <button className={`navbar-toggler ${styles.navToggler}`} type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
        </div>
        <div className={`collapse navbar-collapse ${styles.navbarNav}`} id="navbarNav">
          <ul className={location.pathname === "/Mapa" || location.pathname === "/DetalleMenu" ? ` navbar-nav ${styles.ul2}` : ` navbar-nav ${styles.ul}`} >
            <li className="nav-item">
              <div className="row justify-content-start">
                <div className={`col-3 offset-2 ${styles.itemnav}`}>
                  <img src={iconHome} alt="home icon" />
                </div>
                <div className={`col-7 col-sm`}>
                  <a href="/Inicio">
                    <p>Inicio</p>
                  </a>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <div className="row justify-content-start">
                <div className={`col-3 offset-2 ${styles.itemnav}`}>
                  <img src={iconmenuDestacado} alt="Stared icon" />
                </div>
                <div className={`col-7 col-sm`}>
                  <a href="/Menu#especialidades">
                    <p>Menú Lai Lai</p>
                  </a>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <div className="row justify-content-start">
                <div className={`col-3 offset-2 ${styles.itemnav}`}>
                  <img src={iconPromociones} alt="Promos icon" />
                </div>
                <div className={`col-7 col-sm`}>
                  <a href="/Promociones">
                    <p>Combos</p>
                  </a>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <div className="row justify-content-start">
                <div className={`col-3 offset-2 ${styles.itemnav}`}>
                  <img src={iconDomicilio} alt="Delivery icon" />
                </div>
                <div className={`col-7 col-sm`}>
                  <a href="/Mapa">
                    <p>Ubicaciones</p>
                  </a>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <div className="row justify-content-start">
                <div className={`col-3 offset-2 ${styles.itemnav}`}>
                  <img src={logo} alt="logo icon" />
                </div>
                <div className={`col-7 col-sm`}>
                  <a href="/Nosotros">
                    <p>Nosotros</p>
                  </a>
                </div>
              </div>
            </li>
          </ul>

        </div>
      </nav>
    )
  }
}
export default Navbar;