import React from 'react';
import styles from './menulist.module.css'

import iconespecialidades from '../../assets/iconmenu/platos_fuertes/Especialidades.svg'
import icontiepan from '../../assets/iconmenu/platos_fuertes/a la plancha.svg'
import iconchaw from '../../assets/iconmenu/platos_fuertes/Chawmein _ Arroz Frito.svg'
import iconchop from '../../assets/iconmenu/platos_fuertes/Chop Suey.svg'
import iconpescado from '../../assets/iconmenu/platos_fuertes/Mariscos.svg'
import iconvege from '../../assets/iconmenu/platos_fuertes/Platos Vegetarianos.svg'
import steak from '../../assets/detailmenu/steak.png'

import iconmein from '../../assets/iconmenu/sopas/Sopas Mein.svg'
import iconwantan from '../../assets/iconmenu/sopas/Sopas Wantan.svg'

import { platos } from './menu';
import Social from '../Social';
import { refrescos } from './refrescos';
import { calientes } from './calientes';
import { cervezas } from './cervezas';
import { postres } from './postres';

class MenuList extends React.Component {
  render() {
    const {
      location
    } = this.props;
    return (
      <>
        <div className={`${styles.background} row no-gutters`}>
          <Social />
          <div className="col col-sm col-lg">
            <div className="row ">
              <div className={
                location.hash === "#especialidades" || location.hash === "#Tie_Pan" ||
                  location.hash === "#chow_mean-arroz" || location.hash === "#Chop_suey" ||
                  location.hash === "#aves" || location.hash === "#Carnes" ||
                  location.hash === "#Fideos_de_arroz" || location.hash === "#Vegetarianos" || location.hash === "#Pescados-Mariscos"
                  ?
                  `col col-sm col-lg ${styles.backgrounddiv} ${styles.divImg}`
                  :
                  location.hash === "#sopas_all" || location.hash === "#sopas_mein" ?
                    `col col-sm col-lg ${styles.backgrounddiv2} ${styles.divImg}`
                    :
                    location.hash === "#entradas" ?
                      `col col-sm col-lg ${styles.backgrounddiv3} ${styles.divImg}`
                      :
                      `col col-sm col-lg ${styles.backgrounddiv4} ${styles.divImg}`
              }>
                {(location.hash === "#entradas" || location.hash === "#Machay" ||
                  location.hash === "#Galletas_Chinas" || location.hash === "#Lychee" ||
                  location.hash === "#Longan" || location.hash === "#Wamuy")

                  &&
                  <>
                    <div className={`row  `}>
                      <div className={`col col-sm col-lg `}>
                        <div className={styles.logoHoldertexttitleplatos}>
                          <a className={styles.buttonFuertes} href="/MenuList#entradas">
                            <p className={styles.textdivtop}>Entradas</p>
                          </a>
                        </div>
                      </div>
                      <div className={`col col-sm col-lg `}>
                        <div className={styles.logoHoldertexttitleplatosright}>
                          <a className={styles.buttonFuertes} href="/Menu#especialidades">
                            <p className={styles.textdivtop}>Plato Fuerte</p>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className={`row  `}>
                      <div className={`col col-sm col-lg`}>
                        <div className={styles.logoHoldertexttitlebottom}>
                          <a className={styles.buttonFuertes} href="/Menu#sopas_mein">
                            <p className={styles.textdivtop}>Sopas</p>
                          </a>
                        </div>
                      </div>
                      <div className={`col col-sm col-lg `}>
                        <div className={styles.logoHoldertexttitlebottomright}>
                          <a className={styles.buttonFuertes} href="/Menu/postres_bebidas">
                            <p className={styles.textdivtop}>Postres y Bebidas</p>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col col-sm col-lg'>
                        <div className={styles.logoHoldertexttitleplatosDIM}>
                          <a className={styles.buttonFuertes} href="/Menu#dimsum">
                            <p className={styles.textdivtop}>Dim Sum</p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                }
                {
                  (location.hash === "#sopas" || location.hash === "#sopas_all" ||
                    location.hash === "#sopas_mein") &&
                  <>
                    <div className={`row align-items-center `}>
                      <div className={`col col-sm col-lg `}>
                        <div className={styles.logoHoldertexttitle}>
                          <a className={styles.buttonFuertes} href="/Menu#entradas">
                            <p className={styles.textdivtop}>Entradas</p>
                          </a>
                        </div>
                      </div>
                      <div className={`col col-sm col-lg `}>
                        <div className={styles.logoHoldertexttitleplatosright}>
                          <a className={styles.buttonFuertes} href="/Menu#especialidades">
                            <p className={styles.textdivtop}>Plato Fuerte</p>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className={`row align-items-center `}>
                      <div className={`col col-sm col-lg `}>
                        <div className={styles.logoHoldertexttitlebottomfocus}>
                          <a className={styles.buttonFuertes} href="/Menu#sopas_mein">
                            <p className={styles.textdivtop}>Sopas</p>
                          </a>
                        </div>
                      </div>
                      <div className={`col col-sm col-lg `}>
                        <div className={styles.logoHoldertexttitlebottomright}>
                          <a className={styles.buttonFuertes} href="/Menu/postres_bebidas">
                            <p className={styles.textdivtop}>Postres y Bebidas</p>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col col-sm col-lg'>
                        <div className={styles.logoHoldertexttitleplatosDIM}>
                          <a className={styles.buttonFuertes} href="/Menu#dimsum">
                            <p className={styles.textdivtop}>Dim Sum</p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                }
                {
                  (location.pathname === "/Menu/postres_bebidas")
                  &&
                  <>
                    <div className={`row align-items-center `}>
                      <div className={`col col-sm col-lg `}>
                        <div className={styles.logoHoldertexttitle}>
                          <a className={styles.buttonFuertes} href="/Menu#entradas">
                            <p className={styles.textdivtop}>Entradas</p>
                          </a>
                        </div>
                      </div>
                      <div className={`col col-sm col-lg `}>
                        <div className={styles.logoHoldertexttitleplatosright}>
                          <a className={styles.buttonFuertes} href="/Menu#especialidades">
                            <p className={styles.textdivtop}>Plato Fuerte</p>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className={`row align-items-center `}>
                      <div className={`col col-sm col-lg `}>
                        <div className={styles.logoHoldertexttitlebottom}>
                          <a className={styles.buttonFuertes} href="/Menu#sopas_mein">
                            <p className={styles.textdivtop}>Sopas</p>
                          </a>
                        </div>
                      </div>
                      <div className={`col col-sm col-lg `}>
                        <div className={styles.logoHoldertexttitlebottomfocusright}>
                          <a className={styles.buttonFuertes} href="/Menu/postres_bebidas">
                            <p className={styles.textdivtop}>Postres y Bebidas</p>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col col-sm col-lg'>
                        <div className={styles.logoHoldertexttitleplatosDIM}>
                          <a className={styles.buttonFuertes} href="/Menu#dimsum">
                            <p className={styles.textdivtop}>Dim Sum</p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                }
                {
                  (location.hash === "#platos_fuertes" || location.hash === "#especialidades" ||
                    location.hash === "#Tie_Pan" || location.hash === "#chow_mean-arroz" ||
                    location.hash === "#Chop_suey" || location.hash === "#aves" ||
                    location.hash === "#Carnes" || location.hash === "#Fideos_de_arroz" ||
                    location.hash === "#Vegetarianos" || location.hash === "#Pescados-Mariscos")
                  &&
                  <>
                    <div className={`row align-items-center `}>
                      <div className={`col col-sm col-lg `}>
                        <div className={styles.logoHoldertexttitle}>
                          <a className={styles.buttonFuertes} href="/Menu#entradas">
                            <p className={styles.textdivtop}>Entradas</p>
                          </a>
                        </div>
                      </div>
                      <div className={`col col-sm col-lg `}>
                        <div className={styles.logoHoldertexttitleplatosfocusright}>
                          <a className={styles.buttonFuertes} href="/Menu#especialidades">
                            <p className={styles.textdivtop}>Plato Fuerte</p>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className={`row align-items-center `}>
                      <div className={`col col-sm col-lg `}>
                        <div className={styles.logoHoldertexttitlebottom}>
                          <a className={styles.buttonFuertes} href="/Menu#sopas_mein">
                            <p className={styles.textdivtop}>Sopas</p>
                          </a>
                        </div>
                      </div>
                      <div className={`col col-sm col-lg `}>
                        <div className={styles.logoHoldertexttitlebottomright}>
                          <a className={styles.buttonFuertes} href="/Menu/postres_bebidas">
                            <p className={styles.textdivtop}>Postres y Bebidas</p>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col col-sm col-lg'>
                        <div className={styles.logoHoldertexttitleplatosDIM}>
                          <a className={styles.buttonFuertes} href="/Menu#dimsum">
                            <p className={styles.textdivtop}>Dim Sum</p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                }
                {
                  (location.hash === "#dimsum")
                  &&
                  <>
                    <div className={`row align-items-center `}>
                      <div className={`col col-sm col-lg `}>
                        <div className={styles.logoHoldertexttitle}>
                          <a className={styles.buttonFuertes} href="/Menu#entradas">
                            <p className={styles.textdivtop}>Entradas</p>
                          </a>
                        </div>
                      </div>
                      <div className={`col col-sm col-lg `}>
                        <div className={styles.logoHoldertexttitleplatosright}>
                          <a className={styles.buttonFuertes} href="/Menu#especialidades">
                            <p className={styles.textdivtop}>Plato Fuerte</p>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className={`row align-items-center `}>
                      <div className={`col col-sm col-lg `}>
                        <div className={styles.logoHoldertexttitlebottom}>
                          <a className={styles.buttonFuertes} href="/Menu#sopas_mein">
                            <p className={styles.textdivtop}>Sopas</p>
                          </a>
                        </div>
                      </div>
                      <div className={`col col-sm col-lg `}>
                        <div className={styles.logoHoldertexttitlebottomright}>
                          <a className={styles.buttonFuertes} href="/Menu/postres_bebidas">
                            <p className={styles.textdivtop}>Postres y Bebidas</p>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col col-sm col-lg'>
                        <div className={styles.logoHoldertexttitleplatosDIMfocus}>
                          <a className={styles.buttonFuertes} href="/Menu#dimsum">
                            <p className={styles.textdivtop}>Dim Sum</p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                }
              </div>
            </div>
            {
              location.hash === "#platos_fuertes" &&
              <div className="row ">
                <div className={`col col-sm col-lg`}>
                  <div className={` ${styles.scrollmenu} `}>
                    <img src={iconespecialidades} className={styles.icon} alt="logo" />
                    <a href="/Menu#especialidades">Especialidades</a>
                    <img src={icontiepan} className={styles.icon} alt="logo" />
                    <a href="/Menu#Tie_Pan">Tie Pan</a>
                    <img src={iconchaw} className={styles.icon} alt="logo" />
                    <a href="/Menu#chow_mean-arroz">Chow Mein/Arroz Frito</a>
                    <img src={iconchop} className={styles.icon} alt="logo" />
                    <a href="/Menu#Chop_suey">Chop Suey</a>
                    <img src={iconpescado} className={styles.icon} alt="logo" />
                    <a href="/Menu#Pescados-Mariscos">Mariscos</a>
                    <img src={iconvege} className={styles.icon} alt="logo" />
                    <a href="/Menu#Vegetarianos">Vegetarianos</a>
                    <img src={steak} className={styles.icon} alt="logo" />
                    <a href="/Menu#Carnes">Carnes</a>
                  </div>
                </div>
              </div>
            }
            {
              location.hash === "#sopas" &&
              <div className={`row ${styles.rowscroll}`}>
                <div className={`col col-sm col-lg`}>
                  <div className={`row ${styles.scrollmenu}`}>
                    <div className="col-12 col-sm-12 col-lg-12">
                      <img src={iconmein} className={styles.icon} alt="logo" />
                      <a href="/Menu#sopas_all">Sopas</a>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-12">
                      <img src={iconwantan} className={styles.icon} alt="logo" />
                      <a href="/Menu#sopas_mein">Sopa Mein</a>
                    </div>
                  </div>
                </div>
              </div>
            }
            {
              location.hash === "#entradas" &&
              <div className={`row ${styles.rowscroll}`}>
                <div className={`col col-sm col-lg`}>
                  <div className={`row ${styles.divfull}`}>
                    {/*left */}
                    {
                      Object.values(platos).map((plato, key) => (
                        plato.tipo === "Entradas" &&
                        <div className={`col-6 col-sm-4 col-lg-4 `} key={key}>
                          {/*href se dirige al path indicado en menu.js */}
                          <div className={styles.divplatos}>
                            <a href={`/DetalleMenu${plato.path}`}>
                              <img src={plato.img} className={styles.imgscroll} alt="logo" />
                              <div className={styles.texto}>
                                <p className={styles.title}>{plato.titulo} &nbsp;&nbsp;{plato.precio} </p>
                              </div>
                            </a>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            }
            {
              location.hash === "#dimsum" &&
              <div className={`row ${styles.rowscroll}`}>
                <div className={`col col-sm col-lg`}>
                  <div className={`row ${styles.divfull}`}>
                    {/*left */}
                    {
                      Object.values(platos).map((plato, key) => (
                        plato.tipo === "Dim Sum" &&
                        <div className={`col-6 col-sm-4 col-lg-4 `} key={key}>
                          {/*href se dirige al path indicado en menu.js */}
                          <div className={styles.divplatos}>
                            <a href={`/DetalleMenu${plato.path}`}>
                              <img src={plato.img} className={styles.imgscroll} alt="logo" />
                              <div className={styles.texto}>
                                <p className={styles.title}>{plato.titulo} &nbsp;&nbsp;{plato.precio} </p>
                              </div>
                            </a>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            }
            <div className="row">
              {
                location.hash === "#especialidades" &&
                <div className={`col ${styles.divImgScroll}`}>
                  <div className={`row ${styles.rowscroll}`}>
                    <div className={`col col-sm col-lg `}>
                      <div className={styles.scrollmenu}>
                        <img src={iconespecialidades} className={styles.icon} alt="logo" />
                        <a href="/Menu#especialidades">Especialidades</a>
                        <img src={icontiepan} className={styles.icon} alt="logo" />
                        <a href="/Menu#Tie_Pan">Tie Pan</a>
                        <img src={iconchaw} className={styles.icon} alt="logo" />
                        <a href="/Menu#chow_mean-arroz">Chaw Mein/Arroz Frito</a>
                        <img src={iconchop} className={styles.icon} alt="logo" />
                        <a href="/Menu#Chop_suey">Chop Suey</a>
                        <img src={iconpescado} className={styles.icon} alt="logo" />
                        <a href="/Menu#Pescados-Mariscos">Mariscos</a>
                        <img src={iconvege} className={styles.icon} alt="logo" />
                        <a href="/Menu#Vegetarianos">Vegetarianos</a>
                        <img src={steak} className={styles.icon} alt="logo" />
                        <a href="/Menu#Carnes">Carnes</a>
                      </div>
                    </div>
                  </div>
                  <div className={`row  ${styles.divfull}`}>
                    {/*left */}
                    {
                      Object.values(platos).map((plato, key) => (
                        plato.tipo === "Especialidades" &&
                        <div className={`col-6 col-sm-4 col-lg-4 `} key={key}>
                          {/*href se dirige al path indicado en menu.js */}
                          <div className={styles.divplatos}>
                            <a href={`/DetalleMenu${plato.path}`}>
                              <img src={plato.img} className={styles.imgscroll} alt="logo" />
                              <div className={styles.texto}>
                                <p className={styles.title}>{plato.titulo} &nbsp;&nbsp;{plato.precio} </p>
                              </div>
                            </a>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              }
              {
                location.hash === "#Tie_Pan" &&
                <div className={`col ${styles.divImgScroll}`}>
                  <div className={`row ${styles.rowscroll}`}>
                    <div className={`col col-sm col-lg `}>
                      <div className={styles.scrollmenu}>
                        <img src={iconespecialidades} className={styles.icon} alt="logo" />
                        <a href="/Menu#especialidades">Especialidades</a>
                        <img src={icontiepan} className={styles.icon} alt="logo" />
                        <a href="/Menu#Tie_Pan">Tie Pan</a>
                        <img src={iconchaw} className={styles.icon} alt="logo" />
                        <a href="/Menu#chow_mean-arroz">Chaw Mein/Arroz Frito</a>
                        <img src={iconchop} className={styles.icon} alt="logo" />
                        <a href="/Menu#Chop_suey">Chop Suey</a>
                        <img src={iconpescado} className={styles.icon} alt="logo" />
                        <a href="/Menu#Pescados-Mariscos">Mariscos</a>
                        <img src={iconvege} className={styles.icon} alt="logo" />
                        <a href="/Menu#Vegetarianos">Vegetarianos</a>
                        <img src={steak} className={styles.icon} alt="logo" />
                        <a href="/Menu#Carnes">Carnes</a>
                      </div>
                    </div>
                  </div>
                  <div className={`row ${styles.divfull}`}>
                    {/*left */}
                    {
                      Object.values(platos).map((plato, key) => (
                        plato.tipo === "Tie_Pan" &&
                        <div className={`col-6 col-sm-4 col-lg-4 `} key={key}>
                          {/*href se dirige al path indicado en menu.js */}
                          <div className={styles.divplatos}>
                            <a href={`/DetalleMenu${plato.path}`}>
                              <img src={plato.img} className={styles.imgscroll} alt="logo" />
                              <div className={styles.texto}>
                                <p className={styles.title}>{plato.titulo} &nbsp;&nbsp;{plato.precio} </p>
                              </div>
                            </a>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              }
              {
                location.hash === "#chow_mean-arroz" &&
                <div className={`col ${styles.divImgScroll}`}>
                  <div className={`row ${styles.rowscroll}`}>
                    <div className={`col col-sm col-lg `}>
                      <div className={styles.scrollmenu}>
                        <img src={iconespecialidades} className={styles.icon} alt="logo" />
                        <a href="/Menu#especialidades">Especialidades</a>
                        <img src={icontiepan} className={styles.icon} alt="logo" />
                        <a href="/Menu#Tie_Pan">Tie Pan</a>
                        <img src={iconchaw} className={styles.icon} alt="logo" />
                        <a href="/Menu#chow_mean-arroz">Chaw Mein/Arroz Frito</a>
                        <img src={iconchop} className={styles.icon} alt="logo" />
                        <a href="/Menu#Chop_suey">Chop Suey</a>
                        <img src={iconpescado} className={styles.icon} alt="logo" />
                        <a href="/Menu#Pescados-Mariscos">Mariscos</a>
                        <img src={iconvege} className={styles.icon} alt="logo" />
                        <a href="/Menu#Vegetarianos">Vegetarianos</a>
                        <img src={steak} className={styles.icon} alt="logo" />
                        <a href="/Menu#Carnes">Carnes</a>
                      </div>
                    </div>
                  </div>
                  <div className={`row ${styles.divfull}`}>
                    {/*left */}
                    {
                      Object.values(platos).map((plato, key) => (
                        plato.tipo === "chaw_mean-arroz" &&
                        <div className={`col-6 col-sm-4 col-lg-4 `} key={key}>
                          {/*href se dirige al path indicado en menu.js */}
                          <div className={styles.divplatos}>
                            <a href={`/DetalleMenu${plato.path}`}>
                              <img src={plato.img} className={styles.imgscroll} alt="logo" />
                              <div className={styles.texto}>
                                <p className={styles.title}>{plato.titulo} &nbsp;&nbsp;{plato.precio} </p>
                              </div>
                            </a>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              }
              {
                location.hash === "#Chop_suey" &&
                <div className={`col ${styles.divImgScroll}`}>
                  <div className={`row ${styles.rowscroll}`}>
                    <div className={`col col-sm col-lg `}>
                      <div className={styles.scrollmenu}>
                        <img src={iconespecialidades} className={styles.icon} alt="logo" />
                        <a href="/Menu#especialidades">Especialidades</a>
                        <img src={icontiepan} className={styles.icon} alt="logo" />
                        <a href="/Menu#Tie_Pan">Tie Pan</a>
                        <img src={iconchaw} className={styles.icon} alt="logo" />
                        <a href="/Menu#chow_mean-arroz">Chaw Mein/Arroz Frito</a>
                        <img src={iconchop} className={styles.icon} alt="logo" />
                        <a href="/Menu#Chop_suey">Chop Suey</a>
                        <img src={iconpescado} className={styles.icon} alt="logo" />
                        <a href="/Menu#Pescados-Mariscos">Mariscos</a>
                        <img src={iconvege} className={styles.icon} alt="logo" />
                        <a href="/Menu#Vegetarianos">Vegetarianos</a>
                        <img src={steak} className={styles.icon} alt="logo" />
                        <a href="/Menu#Carnes">Carnes</a>
                      </div>
                    </div>
                  </div>
                  <div className={`row ${styles.divfull}`}>
                    {/*left */}
                    {
                      Object.values(platos).map((plato, key) => (
                        plato.tipo === "Chop_suey" &&
                        <div className={`col-6 col-sm-4 col-lg-4 `} key={key}>
                          {/*href se dirige al path indicado en menu.js */}
                          <div className={styles.divplatos}>
                            <a href={`/DetalleMenu${plato.path}`}>
                              <img src={plato.img} className={styles.imgscroll} alt="logo" />
                              <div className={styles.texto}>
                                <p className={styles.title}>{plato.titulo} &nbsp;&nbsp;{plato.precio} </p>
                              </div>
                            </a>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>

              }
              {
                location.hash === "#Pescados-Mariscos" &&
                <div className={`col ${styles.divImgScroll}`}>
                  <div className={`row ${styles.rowscroll}`}>
                    <div className={`col col-sm col-lg `}>
                      <div className={styles.scrollmenu}>
                        <img src={iconespecialidades} className={styles.icon} alt="logo" />
                        <a href="/Menu#especialidades">Especialidades</a>
                        <img src={icontiepan} className={styles.icon} alt="logo" />
                        <a href="/Menu#Tie_Pan">Tie Pan</a>
                        <img src={iconchaw} className={styles.icon} alt="logo" />
                        <a href="/Menu#chow_mean-arroz">Chaw Mein/Arroz Frito</a>
                        <img src={iconchop} className={styles.icon} alt="logo" />
                        <a href="/Menu#Chop_suey">Chop Suey</a>
                        <img src={iconpescado} className={styles.icon} alt="logo" />
                        <a href="/Menu#Pescados-Mariscos">Mariscos</a>
                        <img src={iconvege} className={styles.icon} alt="logo" />
                        <a href="/Menu#Vegetarianos">Vegetarianos</a>
                        <img src={steak} className={styles.icon} alt="logo" />
                        <a href="/Menu#Carnes">Carnes</a>
                      </div>
                    </div>
                  </div>
                  <div className={`row ${styles.divfull}`}>
                    {/*left */}
                    {
                      Object.values(platos).map((plato, key) => (
                        plato.tipo === "Pescados-Mariscos" &&
                        <div className={`col-6 col-sm-4 col-lg-4 `} key={key}>
                          {/*href se dirige al path indicado en menu.js */}
                          <div className={styles.divplatos}>
                            <a href={`/DetalleMenu${plato.path}`}>
                              <img src={plato.img} className={styles.imgscroll} alt="logo" />
                              <div className={styles.texto}>
                                <p className={styles.title}>{plato.titulo} &nbsp;&nbsp;{plato.precio} </p>
                              </div>
                            </a>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              }
              {
                location.hash === "#Vegetarianos" &&
                <div className={`col ${styles.divImgScroll}`}>
                  <div className={`row ${styles.rowscroll}`}>
                    <div className={`col col-sm col-lg `}>
                      <div className={styles.scrollmenu}>
                        <img src={iconespecialidades} className={styles.icon} alt="logo" />
                        <a href="/Menu#especialidades">Especialidades</a>
                        <img src={icontiepan} className={styles.icon} alt="logo" />
                        <a href="/Menu#Tie_Pan">Tie Pan</a>
                        <img src={iconchaw} className={styles.icon} alt="logo" />
                        <a href="/Menu#chow_mean-arroz">Chaw Mein/Arroz Frito</a>
                        <img src={iconchop} className={styles.icon} alt="logo" />
                        <a href="/Menu#Chop_suey">Chop Suey</a>
                        <img src={iconpescado} className={styles.icon} alt="logo" />
                        <a href="/Menu#Pescados-Mariscos">Mariscos</a>
                        <img src={iconvege} className={styles.icon} alt="logo" />
                        <a href="/Menu#Vegetarianos">Vegetarianos</a>
                        <img src={steak} className={styles.icon} alt="logo" />
                        <a href="/Menu#Carnes">Carnes</a>
                      </div>
                    </div>
                  </div>
                  <div className={`row ${styles.divfull}`}>
                    {/*left */}
                    {Object.values(platos).map((plato, key) => (
                      plato.tipo === "Vegetarianos" &&
                      <div className={`col-6 col-sm-4 col-lg-4 `} key={key}>
                        {/*href se dirige al path indicado en menu.js */}
                        <div className={styles.divplatos}>
                          <a href={`/DetalleMenu${plato.path}`}>
                            <img src={plato.img} className={styles.imgscroll} alt="logo" />
                            <div className={styles.texto}>
                              <p className={styles.title}>{plato.titulo} &nbsp;&nbsp;{plato.precio} </p>
                            </div>
                          </a>
                        </div>
                      </div>
                    ))
                    }
                  </div>
                </div>
              }
              {
                location.hash === "#Carnes" &&
                <div className={`col ${styles.divImgScroll}`}>
                  <div className={`row ${styles.rowscroll}`}>
                    <div className={`col col-sm col-lg `}>
                      <div className={styles.scrollmenu}>
                        <img src={iconespecialidades} className={styles.icon} alt="logo" />
                        <a href="/Menu#especialidades">Especialidades</a>
                        <img src={icontiepan} className={styles.icon} alt="logo" />
                        <a href="/Menu#Tie_Pan">Tie Pan</a>
                        <img src={iconchaw} className={styles.icon} alt="logo" />
                        <a href="/Menu#chow_mean-arroz">Chaw Mein/Arroz Frito</a>
                        <img src={iconchop} className={styles.icon} alt="logo" />
                        <a href="/Menu#Chop_suey">Chop Suey</a>
                        <img src={iconpescado} className={styles.icon} alt="logo" />
                        <a href="/Menu#Pescados-Mariscos">Mariscos</a>
                        <img src={iconvege} className={styles.icon} alt="logo" />
                        <a href="/Menu#Vegetarianos">Vegetarianos</a>
                        <img src={steak} className={styles.icon} alt="logo" />
                        <a href="/Menu#Carnes">Carnes</a>
                      </div>
                    </div>
                  </div>
                  <div className={`row ${styles.divfull}`}>
                    {/*left */}
                    {Object.values(platos).map((plato, key) => (
                      plato.tipo === "Carnes" &&
                      <div className={`col-6 col-sm-4 col-lg-4 `} key={key}>
                        {/*href se dirige al path indicado en menu.js */}
                        <div className={styles.divplatos}>
                          <a href={`/DetalleMenu${plato.path}`}>
                            <img src={plato.img} className={styles.imgscroll} alt="logo" />
                            <div className={styles.texto}>
                              <p className={styles.title}>{plato.titulo} &nbsp;&nbsp;{plato.precio} </p>
                            </div>
                          </a>
                        </div>
                      </div>
                    ))
                    }
                  </div>
                </div>
              }
              {
                location.hash === "#sopas_all" &&
                <div className={`col ${styles.divImgScroll}`}>
                  <div className={`row ${styles.rowscroll}`}>
                    <div className={`col col-sm col-lg `}>
                      <div className={`row ${styles.scrollmenu}`}>
                        <div className="col-3 col-sm-3 col-lg-2 offset-3 offset-lg-0 offset-sm-0">
                          <img src={iconwantan} className={styles.iconsopas} alt="logo" />
                          <a href="/Menu#sopas_mein">Sopa Mein</a>
                        </div>
                        <div className="col-3 col-sm-3 col-lg-2">
                          <img src={iconmein} className={styles.iconsopas} alt="logo" />
                          <a href="/Menu#sopas_all">Sopas Varias</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`row ${styles.divfull}`}>
                    {/*left */}
                    {
                      Object.values(platos).map((plato, key) => (
                        plato.tipo === "Sopas" &&
                        <div className={`col-6 col-sm-4 col-lg-4 `} key={key}>
                          {/*href se dirige al path indicado en menu.js */}
                          <div className={styles.divplatos}>
                            <a href={`/DetalleMenu${plato.path}`}>
                              <img src={plato.img} className={styles.imgscroll} alt="logo" />
                              <div className={styles.texto}>
                                <p className={styles.title}>{plato.titulo} &nbsp;&nbsp;{plato.precio} </p>
                              </div>
                            </a>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              }
              {
                location.hash === "#sopas_mein" &&
                <div className={`col ${styles.divImgScroll}`}>
                  <div className={`row ${styles.rowscroll}`}>
                    <div className={`col col-sm col-lg `}>
                      <div className={`row ${styles.scrollmenu}`}>
                        <div className="col-3 col-sm-3 col-lg-2 offset-3 offset-lg-0 offset-sm-0">
                          <img src={iconwantan} className={styles.iconsopas} alt="logo" />
                          <a href="/Menu#sopas_mein">Sopa Mein</a>
                        </div>
                        <div className="col-3 col-sm-3 col-lg-2">
                          <img src={iconmein} className={styles.iconsopas} alt="logo" />
                          <a href="/Menu#sopas_all">Sopas Varias</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`row ${styles.divfull}`}>
                    {/*left */}
                    {
                      Object.values(platos).map((plato, key) => (
                        plato.tipo === "Mein" &&
                        <div className={`col-6 col-sm-4 col-lg-4 `} key={key}>
                          {/*href se dirige al path indicado en menu.js */}
                          <div className={styles.divplatos}>
                            <a href={`/DetalleMenu${plato.path}`}>
                              <img src={plato.img} className={styles.imgscroll} alt="logo" />
                              <div className={styles.texto}>
                                <p className={styles.title}>{plato.titulo} &nbsp;&nbsp;{plato.precio} </p>
                              </div>
                            </a>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              }

              {/** Bebidas */}
              {
                location.pathname === "/Menu/postres_bebidas" &&

                <div className="col-12 col-sm-10 offset-sm-1">
                  <div className="row">
                    <div className="col offset-sm-1">
                      <h1>POSTRES</h1>
                    </div>
                  </div>
                  {/**Machay*/}
                  <div className="row">
                    <div className={`col offset-sm-1  `}>
                      <p className={styles.drinkTitle}>Machay</p>
                    </div>
                  </div>
                  {
                    Object.values(postres).map((postre, key) => (
                      postre.tipo === "machay" &&
                      <div className="row" key={key}>
                        <div className={`col-8 col-sm-7 offset-sm-1 col-lg-4 `}>
                          <p className={styles.drinkName}>{postre.name}</p>
                        </div>
                        <div className={`col-4 col-sm-2 col-lg-1 offset-lg-2`}>
                          <p className={styles.drinkPrice}>{postre.price}</p>
                        </div>
                      </div>
                    ))
                  }
                  {/**Galletas Chinas*/}
                  <div className="row">
                    <div className={`col offset-sm-1 `}>
                      <p className={styles.drinkTitle}>Galletas</p>
                    </div>
                  </div>
                  {
                    Object.values(postres).map((postre, key) => (
                      postre.tipo === "galleta" &&
                      <div className="row" key={key}>
                        <div className={`col-8 col-sm-7 offset-sm-1 col-lg-4 `}>
                          <p className={styles.drinkName}>{postre.name}</p>
                        </div>
                        <div className={`col-4 col-sm-2 col-lg-1 offset-lg-2`}>
                          <p className={styles.drinkPrice}>{postre.price}</p>
                        </div>
                      </div>
                    ))
                  }
                  {/**Lychee*/}
                  <div className="row">
                    <div className={`col offset-sm-1 `}>
                      <p className={styles.drinkTitle}>Lychee</p>
                    </div>
                  </div>
                  {
                    Object.values(postres).map((postre, key) => (
                      postre.tipo === "lychee" &&
                      <div className="row" key={key}>
                        <div className={`col-8 col-sm-7 offset-sm-1 col-lg-4 offset-lg-1`}>
                          <p className={styles.drinkName}>{postre.name}</p>
                        </div>
                        <div className={`col-4 col-sm-2 col-lg-1 offset-lg-2`}>
                          <p className={styles.drinkPrice}>{postre.price}</p>
                        </div>
                      </div>
                    ))
                  }
                  {/**Longan*/}
                  <div className="row">
                    <div className={`col offset-sm-1`}>
                      <p className={styles.drinkTitle}>Longan</p>
                    </div>
                  </div>
                  {
                    Object.values(postres).map((postre, key) => (
                      postre.tipo === "longan" &&
                      <div className="row" key={key}>
                        <div className={`col-8 col-sm-7 offset-sm-1 col-lg-4`}>
                          <p className={styles.drinkName}>{postre.name}</p>
                        </div>
                        <div className={`col-4 col-sm-2 col-lg-1 offset-lg-2`}>
                          <p className={styles.drinkPrice}>{postre.price}</p>
                        </div>
                      </div>
                    ))
                  }
                  {/**Wamuy*/}
                  <div className="row" id="foo">
                    <div className={`col offset-sm-1`}>
                      <p className={styles.drinkTitle}>Wamuy</p>
                    </div>
                  </div>
                  {
                    Object.values(postres).map((postre, key) => (
                      postre.tipo === "wamuy" &&
                      <div className="row" key={key} >
                        <div className={`col-8 col-sm-7 offset-sm-1 col-lg-4 offset-lg-1`}>
                          <p className={styles.drinkName}>{postre.name}</p>
                        </div>
                        <div className={`col-4 col-sm-2 col-lg-1 offset-lg-2`}>
                          <p className={styles.drinkPrice}>{postre.price}</p>
                        </div>
                      </div>
                    ))
                  }
                  {/**Wamuy*/}
                  <div className="row" id="foo">
                    <div className={`col offset-sm-1`}>
                      <p className={styles.drinkTitle}>Corbatas Dulces</p>
                    </div>
                  </div>
                  {
                    Object.values(postres).map((postre, key) => (
                      postre.tipo === "corbatas" &&
                      <div className="row" key={key} >
                        <div className={`col-8 col-sm-7 offset-sm-1 col-lg-4 offset-lg-1`}>
                          <p className={styles.drinkName}>{postre.name}</p>
                        </div>
                        <div className={`col-4 col-sm-2 col-lg-1 offset-lg-2`}>
                          <p className={styles.drinkPrice}>{postre.price}</p>
                        </div>
                      </div>
                    ))
                  }
                  <div className="row" >
                    <div className="col offset-sm-1">
                      <h1>BEBIDAS</h1>
                    </div>
                  </div>
                  {/**Refrescos */}
                  <div className="row" >
                    <div className="col-5 col-sm-3 offset-sm-1">
                      <p className={styles.drinkTitle}>Refrescos</p>
                    </div>
                    <div className="col-4 offset-3 col-sm-3 offset-sm-5">
                      <p className={styles.drinkTitle}>Pichel</p>
                    </div>
                  </div>
                  {
                    Object.values(refrescos).map((refresco, key) => (
                      <div className="row" key={key}>
                        <div className="col-5 col-sm-4 offset-sm-1">
                          <p className={styles.drinkName}>{refresco.name}</p>
                        </div>
                        <div className="col-3 col-sm-2 offset-sm-1">
                          <p className={styles.drinkPrice}>{refresco.price}</p>
                        </div>
                        <div className="col-3 col-sm-2 offset-sm-1">
                          <p className={styles.drinkPrice}>{refresco.tankard}</p>
                        </div>
                      </div>
                    ))
                  }
                  {/**Bebidas Caliente */}
                  <div className="row">
                    <div className={`col col-sm-4 offset-sm-1 col-lg-4 offset-lg-1 `}>
                      <p className={styles.drinkTitle}>Bebidas Calientes</p>
                    </div>
                  </div>
                  {
                    Object.values(calientes).map((caliente, key) => (
                      <div className="row" key={key}>
                        <div className={`col-8 col-sm-7 offset-sm-1 col-lg-4 `}>
                          <p className={styles.drinkName}>{caliente.name}</p>
                        </div>
                        <div className={`col-4 col-sm-2 col-lg-1 offset-lg-2`}>
                          <p className={styles.drinkPrice}>{caliente.price}</p>
                        </div>
                      </div>
                    ))
                  }
                  {/**Cervezas */}
                  <div className="row">
                    <div className="col col-sm-4 offset-sm-1 col-lg-4 offset-lg-1">
                      <p className={styles.drinkTitle}>Cervezas</p>
                    </div>

                  </div>
                  {
                    Object.values(cervezas).map((cerveza, key) => (
                      <div className="row" key={key}>
                        <div className="col-8 col-sm-7 offset-sm-1 col-lg-4">
                          <p className={styles.drinkName}>{cerveza.nameC}</p>
                        </div>
                        <div className="col-4 col-sm-2 col-lg-1 offset-lg-2">
                          <p className={styles.drinkPriceSmall}>{cerveza.priceC}</p>
                        </div>

                      </div>
                    ))
                  }
                  <div className={styles.divBlank}>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default MenuList;