import React from 'react'

//promociones
import promo1 from '../../assets/combos/Banquete Lai Lai_Web_AGO24.png'
import promo2 from '../../assets/combos/Combo Chengzhi_Web_AGO24.png'
import promo3 from '../../assets/combos/Combo Familiar_Web_AGO24.png'
import promo4 from '../../assets/combos/Combos-D-FaSanKai.png'
import promo5 from '../../assets/combos/Super Banquete_Web_AGO24.png'
import promo6 from '../../assets/combos/Super Combo_Web_AGO24.png'
import promo7 from '../../assets/combos/Combo_Pollo_Asado_Web.png'

import styles from './modal.module.css'

const modals =
  [
    {
      image: promo1,
      id: "promo1",
      title: "Banquete Lai Lai",
      price: "167.00",
      desc: "Banquete Lai Lai incluye:",
      item: ["Arroz frito de pollo o cerdo", "1/2 porción Wantan de Lomito (5unidades)", "Chop Suey de Lomito", "1.5 Lts de gaseosa"],
      footer: "Para compartir 4 personas",
      adomicilio: "*Aplica únicamente para llevar y servicio a domicilio."
    },
    {
      image: promo2,
      id: "promo2",
      title: "Combo Chengzhí",
      price: "Q205.00",
      desc: "Combo Chengzhí incluye:",
      item: ["Chaw Mein de pollo o cerdo", "1/2 porción Wantan de lomito (5 unidades)", "Arroz frito de pollo o cerdo", "Pollo Chengzhí "],
      footer: "Para compartir hasta 6 personas",
      adomicilio: ""

    },
    {
      image: promo3,
      id: "promo3",
      title: "Combo Familiar",
      price: "Q219.00",
      desc: "Combo Familiar incluye:",
      item: ["Arroz frito de pollo o cerdo", "Chaw Mein de pollo o cerdo", "1/2 porción Wantan de lomito (5 unidades", "Chop Suey de lomito", "1.5 Lts de gaseosa)"],
      footer: "Para compartir hasta 6 personas ",
      adomicilio: "*Aplica únicamente para llevar y servicio a domicilio."

    },
    {
      image: promo4,
      id: "promo4",
      title: "Combo Fa San Kai",
      price: "Q171.00",
      desc: "Combo Fa San Kai incluye:",
      item: ["Fa San Kai (pollo al maní)", "Chaw Mein de cerdo","Arroz blanco (1/4 de galón)", "Taquitos de Pollo"],
      footer: "Ideal para compartir de 4 a 5 personas.",
      adomicilio: ""

    },
    

    {
      image: promo5,
      id: "promo5",
      title: "Super Banquete",
      price: "219.00",
      desc: "Super Banquete incluye:",
      item: ["Arroz Frito o Chaw Mein de Lomito", "Sopa Mein de Pollo", "Pollo Chengzhí", "Taquitos de Pollo",  "1.5 Lts de gaseosa"],
      footer: "Perfecto para compartir de 5 a 6 personas.",
      adomicilio: "*Aplica únicamente para llevar y servicio a domicilio."

    },
    {
      image: promo6,
      id: "promo6",
      title: "Súper Combo",
      price: "180.00",
      desc: "Super Combo incluye:",
      item: ["Camarones con verduras en salsa de ostras","1/2 Wantan de lomito", "Chaw Mein de pollo o cerdo", ],
      footer: "Para compartir de 4 a 5 personas",
      adomicilio: ""


    },
 /*    {
      image: promo7,
      id: "promo7",
      title: "Combo Pollo Asado",
      price: "Q177.00",
      desc: "Combo Pollo Asado incluye:",
      item: ["Pollo Asado entero","Arroz frito de pollo o cerdo", "1/2 porción Wantan de Lomito (5unidades)", ],
      footer: "Para compartir de 4 personas",
      adomicilio: ""


    } */
  ]

export default function Modal() {
  return (
    <div>
      {
        modals.map((modal, index) => (
          <div className="modal fade" id={modal.id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" key={index}>
            <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalCenterTitle">{modal.title}</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="container">
                    <div className="row">
                      <div className="col-10 offset-1 col-md-9 offset-md-1 col-lg-6">
                        <div className={styles.imgContainer}>
                          <img src={modal.image} alt="combo" />
                        </div>
                      </div>
                      <div className="col-10 offset-1 col-md-3 offset-md-1 col-lg-4 offset-lg-0">
                        <div className={styles.textContainer}>
                          <p>{modal.desc}</p>
                          {
                            modal.item &&
                            modal.item.map((map, index) => (
                              <ul className={styles.item} key={index}>
                                <li>
                                  {map}
                                </li>
                              </ul>
                            ))
                          }
                          <p>{modal.footer} </p>
                          <p>{modal.price}</p>
                          <p>{modal.adomicilio}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary" data-dismiss="modal">Cerrar</button>
                </div>
              </div>
            </div>
          </div>
        ))
      }
    </div>
  )
}
