import React from 'react';
import styles from './map.module.css'
import button from '../../assets/home/call.png'
import icon from '../../assets/ubicaciones/Artboard 3-min.png'
import { direcciones } from './direcciones';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

class Maps extends React.Component {

  displayMarkers = () => {
    return Object.values(direcciones).map((store, index) => {
      return <Marker
        key={index}
        id={index}
        position={{
          lat: store.lat,
          lng: store.lng
        }}
        title={store.nombre}
        name={store.nombre}
        onClick={() => window.open("https://maps.google.com?q=" + store.lat + "," + store.lng)}
      >
      </Marker>
    })
  }

  render() {
    return (
      <div className={`row ${styles.background}`}>
        <div className={`col col-sm col-lg-8 ${styles.divMap}`}>
          <Map
            google={this.props.google}
            zoom={11}
            style={styles.mapStyles}
            initialCenter={{ lat: 14.6263757, lng: -90.5626005 }}
          >
            {this.displayMarkers()}
          </Map>
        </div>

        <a href="tel:1733" className={styles.callButton}>
          <img src={button} className={styles.button} alt="logo" />
        </a>

        <div className={`col col-sm col-lg ${styles.divDireccion}`}>
          {
            Object.values(direcciones).map((ubicacion, key) => {
              return (
                <div className={`row ${styles.row}`} key={key}>
                  <div className={`col col-sm-2 col-lg-2`} >
                    <div className={styles.img}>
                      <img src={icon} alt="icon" />
                    </div>
                  </div>
                  <div className={`col col-sm col-lg-6 `} >
                    <div className={styles.texto}>
                      <p>
                        {ubicacion.direccion}
                      </p>
                    </div>
                  </div>
                  <div className={`col col-sm col-lg`} >
                    <div className={styles.horario}>
                      <p>
                        {ubicacion.nombre}
                      </p>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyCWVVCxYjdLc2mYJxpqZdjZxa3QhldwOsw'
})(Maps);


