import React from 'react';
import styles from './about.module.css'
import Social from '../Social'

class About extends React.Component {
  render() {
    return (
      <div className={`row ${styles.background} align-items-center`}>
        <Social />
        <div className={styles.layout}></div>
        <div className={`col col-sm col-lg ${styles.divcontainer}`}>
          <div className="row">
            <div className={`col col-sm col-lg ${styles.divtitletop}`}>
              <p className={styles.descubre}>D&nbsp;E&nbsp;S&nbsp;C&nbsp;U&nbsp;B&nbsp;R&nbsp;E</p>
              <p className={styles.titleabout}>Quiénes Somos</p>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className={`col col-sm col-lg ${styles.divtitle}`}>
              <p className={`${styles.textabout}`}>
              Generación tras generación, Restaurantes Lai Lai ha sido caracterizado por sus 
              inigualables platillos de comida china cantonesa.  Resaltamos exquisitos sabores y 
              creamos un festín en el paladar de nuestros clientes.  
              <br/><br/> 
              Hemos logrado transmitir la pasión y experiencia a través 
              de cada una de nuestras recetas.  Cada platillo es 
              preparado con ingredientes frescos y de la más alta 
              calidad, que al contacto con el perol, dan forma y sabor 
              a los platillos más queridos y emblemáticos en nuestra 
              historia.
                <br/><br/>
                Para Restaurantes Lai Lai compartir entre amigos o en familia, es uno de los mejores 
                placeres que le dan el toque final a todos nuestros platillos, 
                haciendo que cada receta... forme parte de la experiencia Lai Lai.
              </p>
            </div>
          </div>
          <div className={`row ${styles.rowbottom} `}>
            <div className={`col col-sm col-lg offset-1 offset-sm-2  offset-lg-2`}>
              <div className={`row justify-content-center ${styles.row}`}>
                <div className={`col-7 offset-1 col-sm-4 col-lg-4 `}>
                  <p className={styles.numberbottom}>+40</p>
                </div>
                <div className={`col-10 offset-2 col-sm-5 offset-lg-1 col-lg-6  ${styles.divbottomtext}`}>
                  <p className={styles.textbottom}>Años de experiencia</p>
                </div>
              </div>
            </div>
            <div className={`col col-sm col-lg ${styles.centerbottom}`}>
              <div  className={`row justify-content-center ${styles.row}`}>
                <div className={`col-11 offset-1 offset-lg-2 col-sm-2 col-lg-3 `}>
                  <p className={styles.numberbottom}>26</p>
                </div>
                <div className={`col-7 offset-2 col-sm-6 offset-lg-1 col-lg-6 ${styles.divbottomtext} `}>
                  <p className={styles.textbottom}>Sucursales</p>
                </div>
              </div>
            </div>
            <div className="col col-sm col-lg">
              <div  className={`row justify-content-center ${styles.row}`}>
                <div className={`col-10 col-sm-5 col-lg-4`}>
                  <p className={styles.numberbottom}>+100</p>
                </div>
                <div className={`col-7 offset-2 col-sm-5 col-lg-6 ${styles.divbottomtext}`}>
                  <p className={styles.textbottom}>Recetas diferentes</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
}
export default About;

