import React from 'react';
import styles from './home.module.css'
import logo from '../../assets/home/logo_rojo.png'

class Home extends React.Component {
  render() {
    return (
      <div className={`row ${styles.background} align-items-center`}>
        <div className={styles.layout}></div>
        <div className="col col-sm col-lg">
          <div className="row">
            <div className="col col-sm col-lg">
              <div className={styles.logoHolder}>
                <img src={logo} alt="logo" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col col-sm col-lg">
              <div className={styles.textHolder}>
                <p>¡Doblemente Delicioso!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Home;

