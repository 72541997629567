import React from 'react';
import styles from './reviews.module.css'
//import menu1 from '../../assets/menu/1.jpg';
import arrowR from '../../assets/menu/arrowR.png';
import arrowL from '../../assets/menu/arrowL.png';


import Carousel, { arrowsPlugin, Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';


class Reviews extends React.Component {
  constructor() {
    super()
    this.state = {
      value: 0,
      slides: [
        (
          <div className={styles.carContainer}>
            <div className={styles.divtestimonios}>
              <p className={`${styles.textTitle}`}>
                Testimonios
            </p>
            </div>
            <p className={`${styles.textname}`}>
              Luis Aguilar
            </p>
            <p className={`${styles.textitem}`}>
              Rica comida, años degustando de su delicioso sabor <span role="img" aria-label="smile face">🙂🙂</span>
            </p>
            <p className={`${styles.textnamesecond}`}>
              Sandra Carballo
            </p>
            <p className={`${styles.textitembottom}`}>
              Cumple medidas sanitarias en cuanto a desinfección y espacio.
              Buen sabor. El mesero que nos atendió, excelente servicio. Parque disponible. Muy recomendable
            </p>
          </div>
        ),
        (
          <div className={styles.carContainer}>
            <p className={`${styles.textTitle}`}>
              Testimonios
            </p>
            <p className={`${styles.textname}`}>
              Daniel GT
            </p>
            <p className={`${styles.textitem}`}>
              La atención es muy muy buena, la comida es muy
              deliciosa. Se encuentra en una  buena ubicación y
              es ideal para compartir en familia, con amigos y con tu pareja. MUY RECOMENDADO =)
            </p>
            <p className={`${styles.textnamesecond}`}>
              Eduardo carcamo
            </p>
            <p className={`${styles.textitembottom}`}>
              Buenísima experiencia desde la entrada, la decoración, ambientación, detalles, atención etc. La comida es buenísima, preparada en el instante y servida de la mejor manera. Exquisitos platillos orientales. Regresaré cada vez que pueda.
            </p>
          </div>
        ),

        (
          <div className={styles.carContainer}>
            <p className={`${styles.textTitle}`}>
              Testimonios
            </p>
            <p className={`${styles.textname}`}>
              Javier Vasquez
            </p>
            <p className={`${styles.textitem}`}>
              Muy buen servicio las veces que he pedido a domicilio me han cumplido bien, ademas el sabroso arroz frito
              y el pollo con naranja muy bueno, los recomiendo porque vale la pena la verdad, me gusta mucho cada uno de
              los productos que venden.
            </p>
            <p className={`${styles.textnamesecond}`}>
              Karina Judith Oliva Morales de Escalante
            </p>
            <p className={`${styles.textitembottom}`}>
              Me encanta la comida china. Todo muy sabroso me encanto el pato, recomendado.
            </p>
          </div>
        ),
        (
          <div className={styles.carContainer}>
            <p className={`${styles.textTitle}`}>
              Testimonios
            </p>
            <p className={`${styles.textname}`}>
              Paola Ramirez
            </p>
            <p className={`${styles.textitem}`}>
              Me encanta Lai Lai! Toda su comida es exquisita y el servicio es uno de los mejores. Muy recomendable
            </p>
            <p className={`${styles.textnamesecond}`}>
              Adrian Tobar
            </p>
            <p className={`${styles.textitembottom}`}>
              Lógicamente lo que más me gustó de Lai Lai fue su sabor y calidad de su comida, la variedad de esa exquisita comida oriental y ese buen servicio al cliente.
              Felicitaciones!
            </p>
          </div>
        ),
      
     

      ],
      slidesWeb: [
        (
          <div className={styles.carContainer}>
            <p className={`${styles.textTitle}`}>
              Testimonios
            </p>
            <p className={`${styles.textname}`}>
              Luis Aguilar
            </p>
            <p className={`${styles.textitem}`}>
              Rica comida, años degustando de su delicioso sabor <span role="img" aria-label="smile face">🙂🙂</span>
            </p>
            <p className={`${styles.textnameright}`}>
              Sandra Carballo
            </p>
            <p className={`${styles.textitembottomright}`}>
              Cumple medidas sanitarias en cuanto a desinfección y espacio.
              Buen sabor. El mesero que nos atendió, excelente servicio. Parque disponible. Muy recomendable
            </p>
            <p className={`${styles.textnamesecond}`}>
              Daniel GT
            </p>
            <p className={`${styles.textitembottom}`}>
              La atención es muy muy buena, la comida es muy
              deliciosa. Se encuentra en una  buena ubicación y
              es ideal para compartir en familia, con amigos y con tu pareja. MUY RECOMENDADO =)
            </p>
            <p className={`${styles.textnamesecondright}`}>
              Eduardo carcamo
            </p>
            <p className={`${styles.textitembottomrightsecond}`}>
              Buenísima experiencia desde la entrada, la decoración, ambientación, detalles, atención etc. La comida es buenísima,
              preparada en el instante y servida de la mejor manera. Exquisitos platillos orientales. Regresaré cada vez que pueda.
            </p>
          </div>
        ),
     


        (
          <div className={styles.carContainer}>
            <p className={`${styles.textTitle}`}>
              Testimonios
            </p>
            <p className={`${styles.textname}`}>
              Javier Vasquez
            </p>
            <p className={`${styles.textitem}`}>
              Muy buen servicio las veces que he pedido a domicilio me han cumplido bien, ademas el sabroso arroz frito
              y el pollo con naranja muy bueno, los recomiendo porque vale la pena la verdad, me gusta mucho cada uno de
              los productos que venden.
            </p>
            <p className={`${styles.textnameright}`}>
              Karina Judith Oliva Morales de Escalante
            </p>
            <p className={`${styles.textitembottomright}`}>
              Me encanta la comida china. Todo muy sabroso me encanto el pato, recomendado.
            </p>
            <p className={`${styles.textnamesecond}`}>
              Paola Ramirez
            </p>
            <p className={`${styles.textitembottom}`}>
              Me encanta Lai Lai! Toda su comida es exquisita y el servicio es uno de los mejores. Muy recomendable
            </p>
            <p className={`${styles.textnamesecondright}`}>
              Adrian Tobar
            </p>
            <p className={`${styles.textitembottomrightsecond}`}>
              Lógicamente lo que más me gustó de Lai Lai fue su sabor y calidad de su comida, la variedad de esa exquisita comida oriental y ese buen servicio al cliente.
              Felicitaciones!
            </p>
          </div>
        ),
       
     

      ],
    }
    this.onchange = this.onchange.bind(this);
  }


  onchange(value) {
    this.setState({ value });
  }
  render() {
    return (
      <div className={`row ${styles.background} `}>
        <div className={`col col-sm col-lg ${styles.divcarousemobile}`}>
          <Carousel
            plugins={[
              {
                resolve: arrowsPlugin,
                options: {
                  arrowLeft: <img src={arrowL} className={`${styles.itemimgCarouselleft}`} alt="logo" />,
                  arrowLeftDisabled: <img name="angle-left" alt="logo" />,
                  arrowRight: <img src={arrowR} className={`${styles.itemimgCarouselright}`} alt="logo" />,
                  arrowRightDisabled: <img name="angle-right" alt="logo" />,
                  addArrowClickHandler: true,
                }
              },
              'infinite',
              'centered',
            ]}
            value={this.state.value}
            slides={this.state.slides}
            onChange={this.onchange}
          />
          <Dots value={this.state.value} onChange={this.onchange} number={this.state.slides.length} className={styles.dots} />
        </div>
        <div className={`col col-sm col-lg ${styles.divcarouseweb}`}>
          <Carousel
            plugins={[
              {
                resolve: arrowsPlugin,
                options: {
                  arrowLeft: <img src={arrowL} className={`${styles.itemimgCarouselleft}`} alt="logo" />,
                  arrowLeftDisabled: <img name="angle-left" alt="logo" />,
                  arrowRight: <img src={arrowR} className={`${styles.itemimgCarouselright}`} alt="logo" />,
                  arrowRightDisabled: <img name="angle-right" alt="logo" />,
                  addArrowClickHandler: true,
                }
              },
              'infinite',
              'centered',
            ]}
            value={this.state.value}
            slides={this.state.slidesWeb}
            onChange={this.onchange}
          />
          <Dots value={this.state.value} onChange={this.onchange} number={this.state.slides.length} className={styles.dots} />
        </div>
      </div>
    )
  }
}
export default Reviews;

