import React from 'react'
import styles from './starmenu.module.css'

import plato from '../../assets/MenuIMG/Platos_Fuertes/PATO/PAENT_MENU-normal.png'
import chow from '../../assets/MenuIMG/Platos_Fuertes/CHAW MEIN (FA y HK)/CHAWMONASTERIO_MENU-normal.png'



function StarMenu() {
  return (
    <div className="row">
      <div className="col col-sm col-lg">
        <div className="row">
          <div className="col col-sm col-lg">
            <p className={styles.titulo}>Recomendación del Chef</p>
          </div>
        </div>
        <div className="row">
          <div className={`${styles.divcontainer} col-10 offset-1 col-sm-4 offset-sm-1 col-lg-3 offset-lg-2`}>
            <div className={styles.starMenuContainerLeft}>
              <div className="row">
                <div className="col-7 offset-1">
                  <p className={styles.containerTitle}>
                    Pato
                  </p>
                </div>
                <div className="col-3">
                  <img src={plato} alt="top view"/>
                </div>
              </div>
              <div className="row">
                <div className="col-10 offset-1">
                  <p className={styles.containerText}>
                  Delicioso pato sazonado con variedad de especias chinas y un 
                  cocimiento especial para un horneado único.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-5 offset-5 col-sm-3 offset-sm-7 col-lg-2 offset-lg-8">
                  <a href="/DetalleMenu#Pato_Entero" className={styles.containerButton}>
                    <p>
                      Ir al platillo
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.divcontainer} col-10 offset-1 col-sm-4 offset-sm-2 col-lg-3 offset-lg-2`}>
            <div className={styles.starMenuContainerRight}>
              <div className="row">
                <div className="col-7 offset-1">
                  <p className={styles.containerTitle}>
                  Chaw Mein Monasterio 
                  </p>
                </div>
                <div className="col-3">
                  <img src={chow} alt="top view" />
                </div>
              </div>
              <div className="row">
                <div className="col-10 offset-1">
                  <p className={styles.containerText}>
                  ¡Delicioso y jugoso! Fideos artesanales mezclados con apio, brócoli, 
                  arveja china, hongos chinos, hongos Sin Ku, champiñones y retoño de bambú, en 
                  nuestra exquisita salsa de ostras de la casa.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-5 offset-5 col-sm-3 offset-sm-7 col-lg-2 offset-lg-8">
                  <a href="/DetalleMenu#Chaw_Mein_Monasterio_Chino" className={styles.containerButton}>
                    <p>
                      Ir al platillo
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.floatBG}>
        <div className={styles.left}>
        </div>
        <div className={styles.right}>
        </div>
      </div>
    </div>
  )
}

export default StarMenu
