const cervezas ={
  1:{
    "nameC": "Cervezas Nacionales",
    "priceC": "Q25.00",
    "nameP": "Kalhúa",
    "priceP": "Q25.00"
  },
  2:{
    "nameC": "Cervezas Extranjeras",
    "priceC": "Q30.00",
    "nameP": "Menta Verde",
    "priceP": "Q25.00"
  },
  3:{
    "nameC": "Extra de Michelada",
    "priceC": "Q10.00",
    "nameP": "Amareto",
    "priceP": "Q25.00"
  },
  4:{
    "nameC": "Extra de Chelada",
    "priceC": "Q5.00",
    "nameP": "",
    "priceP": ""
  }
}
export {cervezas};