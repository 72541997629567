//icon
import chicken from '../../assets/detailmenu/chicken.png'
import shrimp from '../../assets/detailmenu/shrimp.png'
import steak from '../../assets/detailmenu/steak.png'
import mixto from '../../assets/detailmenu/mixto-min.png'
import grande from '../../assets/detailmenu/plato_grande-min.png'
import pequeno from '../../assets/detailmenu/plato_pequeno-min.png'

import sanchoy from '../../assets/platos/especialidades/SANCHOYCA_MENU-normal.png'
import pato from '../../assets/platos/especialidades/PAENT_MENU-normal.png'
import camaronessalp from '../../assets/platos/especialidades/CAMARONSALP_MENU-normal.png'
import perscadoVapor from '../../assets/platos/especialidades/PESVA_MENU-normal.png'
import cacerola from '../../assets/MenuIMG/SUBCATEGORIAS/ESPECIALIDADES/CACERVAR_MENU-normal.png'

import jalapeño from '../../assets/platos/especialidades/JALAGCA_MENU-normal.png'
import hongosrellenos from '../../assets/platos/especialidades/HONGORELL_MENU-normal.png'
import mejillones from '../../assets/platos/especialidades/MEJPEROL_MENU-normal.png'
import sinku from '../../assets/platos/especialidades/POSINKU_MENU-normal.png'
import mao from '../../assets/platos/especialidades/MAPOTAFU_MENU-normal.png'

//FA
import famixto from '../../assets/MenuIMG/FA/FACURRYM_MENU-normal.png'
import facamaron from '../../assets/MenuIMG/FA/FACURRYCA_MENU-normal.png'
import fapollo from '../../assets/MenuIMG/FA/FACURRYP_MENU-normal.png'
import falomito from '../../assets/MenuIMG/FA/FACURRYL_MENU-normal.png'
import facerdo from '../../assets/MenuIMG/FA/FACURRYC_MENU-normal.png'

//sopas mein
import sopameinmixta from '../../assets/MenuIMG/Sopas/SOPAS MEIN _ MEIN FA/MEINM_MENU-normal.png'
import sopameinlomito from '../../assets/MenuIMG/Sopas/SOPAS MEIN _ MEIN FA/MEINL_MENU-normal.png'
import sopameinpollo from '../../assets/MenuIMG/Sopas/SOPAS MEIN _ MEIN FA/MEINPA_MENU-normal.png'
import sopameincerdo from '../../assets/MenuIMG/Sopas/SOPAS MEIN _ MEIN FA/MEINCE_MENU-normal.png'
import sopameincamaron from '../../assets/MenuIMG/Sopas/SOPAS MEIN _ MEIN FA/MEINCA_MENU-normal.png'
import sopameinpato from '../../assets/MenuIMG/Sopas/SOPAS MEIN _ MEIN FA/MEINP_MENU-normal.png'
import sopameinpescado from '../../assets/MenuIMG/Sopas/SOPAS MEIN _ MEIN FA/MEINPES_MENU-normal.png'

import guisado from '../../assets/faltantes/BERJALGUI_MENU-normal.png'
import berenguisado from '../../assets/faltantes/BERGUI_MENU-normal.png'
import berenrelle from '../../assets/faltantes/BEREMPA_MENU-normal.png'
import jalaberenrelle from '../../assets/faltantes/BERJALEMPA_MENU-normal.png'
import calamar from '../../assets/faltantes/CALAMARVER_MENU-normal.png'
import caracol from '../../assets/faltantes/CARACOLVER_MENU-normal.png'
import caracolplancha from '../../assets/faltantes/CARACOLVERCHI_MENU-normal.png'

import sopaver from '../../assets/faltantes/MEINPV_MENU-normal.png'
import patohongos from '../../assets/faltantes/PAHONV_MENU-normal.png'

//sopas
import sopasoikao from '../../assets/MenuIMG/Sopas/SOPAS WANTAN - SOI KAO - CREMAS/SOIKAOFA_MENU-normal.png'
import sopacremalomo from '../../assets/MenuIMG/Sopas/SOPAS WANTAN - SOI KAO - CREMAS/CREMAL_MENU-normal.png'
import sopacangrejo from '../../assets/MenuIMG/Sopas/SOPAS VARIEDAD/SCANGREJO_MENU-normal.png'
import sopawantancamaron from '../../assets/MenuIMG/Sopas/SOPAS WANTAN - SOI KAO - CREMAS/SWANTANCA_MENU-normal.png'
import sopavariadocamaron from '../../assets/MenuIMG/Sopas/SOPAS VARIEDAD/SMARISCOS_MENU-normal.png'
import sopavariado from '../../assets/MenuIMG/Sopas/SOPAS VARIEDAD/SVARIEDAD_MENU-normal.png'
import sopapato from '../../assets/MenuIMG/Sopas/SOPAS VARIEDAD/SPATO_MENU-normal.png'
import sopanorte from '../../assets/MenuIMG/Sopas/SOPAS VARIEDAD/SNORTE_MENU-normal.png'

//entradas img
import wantan_camaron from '../../assets/MenuIMG/Entradas/ENTRADAS/WANTANCA_MENU-normal.png';
import cha_sui from '../../assets/MenuIMG/Entradas/CERDO/CHASIU_MENU-normal.png';
import med_cha_sui from '../../assets/MenuIMG/Entradas/CERDO/MEDCHASIU_MENU-normal.png';

import tacos_lailai from '../../assets/MenuIMG/Entradas/ENTRADAS/TACLAI_MENU-normal.png';
import tacos_pollo from '../../assets/MenuIMG/Entradas/ENTRADAS/TACP_MENU-normal.png';
import tacos_pescado from '../../assets/MenuIMG/Entradas/ENTRADAS/TACPES_MENU-normal.png';
import tacos_mixto from '../../assets/MenuIMG/Entradas/ENTRADAS/TACM_MENU-normal.png';
import camaron_empanizado from '../../assets/MenuIMG/SUBCATEGORIAS/ENTRADAS/CAMARONEMP_MENU-normal.png';
import pollofrito from '../../assets/MenuIMG/Platos_Fuertes/POLLO/POFRITO_MENU-normal.png';
import sample_camaron from '../../assets/MenuIMG/Entradas/ENTRADAS/SAMLP_MENU-normal.png';
import corbatas from '../../assets/MenuIMG/Entradas/ENTRADAS/CORBATAS_MENU-normal.png';

//vegetariano
import hongovege from '../../assets/faltantes/HONGOV_MENU-normal.png'
import cacerovege from '../../assets/MenuIMG/SUBCATEGORIAS/VEGETARIANO/CACERVEG_MENU-normal.png'
import chopvege from '../../assets/MenuIMG/SUBCATEGORIAS/VEGETARIANO/CHOPV_MENU-normal.png'
import chawmeinvege from '../../assets/MenuIMG/SUBCATEGORIAS/VEGETARIANO/CHAWMONASTERIO_MENU-normal.png'
import verduras from '../../assets/MenuIMG/SUBCATEGORIAS/VEGETARIANO/KOSTRAS_MENU-normal.png'
import chawvege from '../../assets/MenuIMG/Platos_Fuertes/CHAW MEIN (FA y HK)/CHAWV_MENU-normal.png'
import verdurasmixtas from '../../assets/MenuIMG/Platos_Fuertes/VEGETARIANO/VEROSTRAS_MENU-normal.png'

//pescados-mariscos
import pescadover from '../../assets/MenuIMG/Platos_Fuertes/PESCADO/PESV_MENU-normal.png'
import filete from '../../assets/MenuIMG/Platos_Fuertes/PESCADO/PESSOYA_MENU-normal.png'
import pescadoempa from '../../assets/MenuIMG/Platos_Fuertes/PESCADO/PESEMP_MENU-normal.png'

import camver from '../../assets/MenuIMG/Platos_Fuertes/CAMARÓN/CAMARONV_MENU-normal.png'
import camaegg from '../../assets/MenuIMG/Platos_Fuertes/CAMARÓN/CAMARONEGG_MENU-normal.png'
import camtau from '../../assets/MenuIMG/Platos_Fuertes/CAMARÓN/CAMARONTAUSI_MENU-normal.png'
import camsoya from '../../assets/MenuIMG/Platos_Fuertes/CAMARÓN/CAMARONSOYA_MENU-normal.png'
import camgrande from '../../assets/MenuIMG/Platos_Fuertes/CAMARÓN/CAMARONAJO_MENU-normal.png'

//tie pan
import camaronplancha from '../../assets/MenuIMG/SUBCATEGORIAS/TIE PIE (A LA PLANCHA)/CAMARONPLANCHA_MENU-normal.png'
import carnevariedad from '../../assets/MenuIMG/SUBCATEGORIAS/TIE PIE (A LA PLANCHA)/PLANCHAVAR_MENU-normal.png'
import lomoplancha from '../../assets/MenuIMG/SUBCATEGORIAS/TIE PIE (A LA PLANCHA)/PLANCHAL_MENU-normal.png'
import pollolancha from '../../assets/MenuIMG/SUBCATEGORIAS/TIE PIE (A LA PLANCHA)/PLANCHAP_MENU-normal.png'
import costillalancha from '../../assets/MenuIMG/SUBCATEGORIAS/TIE PIE (A LA PLANCHA)/COSTILLASALSA_MENU-normal.png'

//chop sue
import chopmixto from '../../assets/MenuIMG/Platos_Fuertes/CHOP SUEY, SAN CHOY PAU _ CHAW SAM SI/CHOPM_MENU-normal.png'
import chopcama from '../../assets/MenuIMG/Platos_Fuertes/CHOP SUEY, SAN CHOY PAU _ CHAW SAM SI/CHOPCA_MENU-normal.png'
import choplomo from '../../assets/MenuIMG/Platos_Fuertes/CHOP SUEY, SAN CHOY PAU _ CHAW SAM SI/CHOPL_MENU-normal.png'
import choppollo from '../../assets/MenuIMG/Platos_Fuertes/CHOP SUEY, SAN CHOY PAU _ CHAW SAM SI/CHOPP_MENU-normal.png'
import chopcerdo from '../../assets/MenuIMG/Platos_Fuertes/CHOP SUEY, SAN CHOY PAU _ CHAW SAM SI/CHOPCE_MENU-normal.png'
import lomover from '../../assets/MenuIMG/Platos_Fuertes/LOMITO/LOMVER_MENU-normal.png'
import lomotau from '../../assets/MenuIMG/Platos_Fuertes/LOMITO/LOMTAUSI_MENU-normal.png'
import lomohongos from '../../assets/MenuIMG/Platos_Fuertes/LOMITO/LOMH_MENU-normal.png'
import lomocurry from '../../assets/MenuIMG/Platos_Fuertes/LOMITO/LOMCURRY_MENU-normal.png'
import lomoegg from '../../assets/MenuIMG/Platos_Fuertes/LOMITO/LOMEGG_MENU-normal.png'
import lomoCerdoPina from '../../assets/MenuIMG/Platos_Fuertes/LOMITO/CEPINA_MENU-normal.png'
import lomopina from '../../assets/MenuIMG/Platos_Fuertes/LOMITO/LOMPINA_MENU-normal.png'
import pollohongos from '../../assets/MenuIMG/Platos_Fuertes/POLLO/POOSTRAS_MENU-normal.png'
import pollopina from '../../assets/MenuIMG/Platos_Fuertes/POLLO/POPINA_MENU-normal.png'
import pollocurry from '../../assets/MenuIMG/Platos_Fuertes/POLLO/POCURRY_MENU-normal.png'
import pollover from '../../assets/MenuIMG/Platos_Fuertes/POLLO/POVER_MENU-normal.png'
import pollochen from '../../assets/MenuIMG/Platos_Fuertes/POLLO/PCHENGZHI_MENU-normal.png'

import patopina from '../../assets/MenuIMG/Platos_Fuertes/PATO/PAPINA_MENU-normal.png'
import patover from '../../assets/MenuIMG/Platos_Fuertes/PATO/PAVER_MENU-normal.png'

import chasiuver from '../../assets/MenuIMG/Entradas/CERDO/CHASIUVER_MENU-normal.png'
import chasiuHONGOS from '../../assets/MenuIMG/Entradas/CERDO/CHASIUH_MENU-normal.png'
import costiilasazo from '../../assets/faltantes/COSTILLASAL_MENU-normal.png'
import fasankai from '../../assets/MenuIMG/CATEGORÍAS/Carnes/FASANKAI_MENU-normal.png'

//chaw mein
import arrozmixto from '../../assets/MenuIMG/Platos_Fuertes/ARROZ/ARROZM_MENU-normal.png'
import arrozcama from '../../assets/MenuIMG/Platos_Fuertes/ARROZ/ARROZCACHA_MENU-normal.png'
import arrozcamagrande from '../../assets/MenuIMG/Platos_Fuertes/ARROZ/ARROZCAG_MENU-normal-1.png'
import arrozpollo from '../../assets/MenuIMG/Platos_Fuertes/ARROZ/ARROZP_MENU-normal.png'
import arrozlomito from '../../assets/MenuIMG/Platos_Fuertes/ARROZ/ARROZL_MENU-normal.png'
import arrozcerdo from '../../assets/MenuIMG/Platos_Fuertes/ARROZ/ARROZCE_MENU-normal.png'
import arrozvege from '../../assets/MenuIMG/Platos_Fuertes/ARROZ/ARROZV_MENU-normal.png'

import chawmixto from '../../assets/MenuIMG/Platos_Fuertes/CHAW MEIN (FA y HK)/CHAWM_MENU-normal.png'
import chawcama from '../../assets/MenuIMG/Platos_Fuertes/CHAW MEIN (FA y HK)/CHAWCA_MENU-normal.png'
import chawcagrande from '../../assets/MenuIMG/Platos_Fuertes/CHAW MEIN (FA y HK)/CHAWCAG_MENU-normal.png'
import chawpollo from '../../assets/MenuIMG/Platos_Fuertes/CHAW MEIN (FA y HK)/CHAWP_MENU-normal.png'
import chawmlomito from '../../assets/MenuIMG/Platos_Fuertes/CHAW MEIN (FA y HK)/CHAWL_MENU-normal.png'
import chawcerdo from '../../assets/MenuIMG/Platos_Fuertes/CHAW MEIN (FA y HK)/CHAWCE_MENU-normal.png'

//dimsum
import duplingCa from '../../assets/MenuIMG/dimsum/DumplingCa.jpg';
import Costillasvapor from '../../assets/MenuIMG/dimsum/Costillasvapor.jpg';
import Pataspollo from '../../assets/MenuIMG/dimsum/Pataspollo.jpg';
import Albondigascerdo from '../../assets/MenuIMG/dimsum/Albondigascerdo.jpg';
import EmpanadaCebollin from '../../assets/MenuIMG/dimsum/EmpanadaCebollin.jpg';
import AlbondigaRes from '../../assets/MenuIMG/dimsum/AlbondigaRes.jpg';
import BolloCerdo from '../../assets/MenuIMG/dimsum/BolloCerdo.jpg';
import Bollodorado from '../../assets/MenuIMG/dimsum/Bollodorado.jpg';
import BolloManjar from '../../assets/MenuIMG/dimsum/BolloManjar.jpg';
import Dumplingfrito from '../../assets/MenuIMG/dimsum/Dumplingfrito.jpg';
import Tamalchino from '../../assets/MenuIMG/dimsum/Tamalchino.jpg';
import Panzares from '../../assets/MenuIMG/dimsum/Panzares.jpg';
import Rolloslomito from '../../assets/MenuIMG/dimsum/Rolloslomito.jpg';
import ChaSiuCheon from '../../assets/MenuIMG/dimsum/ChaSiuCheon.jpg';
import HaCheon from '../../assets/MenuIMG/dimsum/HaCheon.jpg';
import YuPin from '../../assets/MenuIMG/dimsum/YuPin.jpg';
import Rollossimples from '../../assets/MenuIMG/dimsum/Rollossimples.jpg';
import TanTaat from '../../assets/MenuIMG/dimsum/TanTaat.jpg';
import Costillasconajo from '../../assets/MenuIMG/dimsum/Costillasconajo.jpg';
import Mataicou from '../../assets/MenuIMG/dimsum/Mataicou.jpg';
import Lopacou from '../../assets/MenuIMG/dimsum/Lopacou.jpg';
import Empanadapescado from '../../assets/MenuIMG/dimsum/Empanadapescado.jpg';
import HamSoiKoc from '../../assets/MenuIMG/dimsum/HamSoiKoc.jpg';
import Sopadecarnes from '../../assets/MenuIMG/dimsum/Sopadecarnes.jpg';
import Sopaconmarrano from '../../assets/MenuIMG/dimsum/Sopaconmarrano.jpg';
import Sopapescado from '../../assets/MenuIMG/dimsum/Sopapescado.jpg';
import Sopalomito from '../../assets/MenuIMG/dimsum/Sopalomito.jpg';
import Sopachoisam from '../../assets/MenuIMG/dimsum/Sopachoisam.jpg';
import Sopalechuga from '../../assets/MenuIMG/dimsum/Sopalechuga.jpg';
import ChaoMeinespecial from '../../assets/MenuIMG/dimsum/ChaoMeinespecial.jpg';
import FideoJengibre from '../../assets/MenuIMG/dimsum/FideoJengibre.jpg';
import FideoChaSiu from '../../assets/MenuIMG/dimsum/FideoChaSiu.jpg';
import BocadosalsaXO from '../../assets/MenuIMG/dimsum/BocadosalsaXO.jpg';
import Calamaresfritos from '../../assets/MenuIMG/dimsum/Calamaresfritos.jpg';
import ChanBaohorneado from '../../assets/MenuIMG/dimsum/ChanBaohorneado.jpg';
import NuoMaiChi from '../../assets/MenuIMG/dimsum/NuoMaiChi.jpg';
import Churroschinos from '../../assets/MenuIMG/dimsum/Churroschinos.jpg';
import PoLoBao from '../../assets/MenuIMG/dimsum/PoLoBao.jpg';
import Taquitosbanano from '../../assets/MenuIMG/dimsum/Taquitosbanano.jpg';
import LaoSaPaoHuevoSalado from '../../assets/MenuIMG/dimsum/LaoSaPaoHuevoSalado.jpg';
import YeChiBao from '../../assets/MenuIMG/dimsum/YeChiBao.jpg';
import Jalapeñopescado from '../../assets/MenuIMG/dimsum/Jalapenopescado.jpg';
import TaoSaBaofrijol from '../../assets/MenuIMG/dimsum/LaoSaPaoHuevoSalado.jpg';
import BolitasAjonjoli from '../../assets/MenuIMG/dimsum/BolitasAjonjoli.jpg';
import Bocadomalanga from '../../assets/MenuIMG/dimsum/Bocadomalanga.jpg';
import TartaletaCoco from '../../assets/MenuIMG/dimsum/TartaletaCoco.jpg';
import chasisouempanada from '../../assets/MenuIMG/dimsum/Cha Siu Sou_Empanada de Cha Siu.jpg'


const platos = {
  //path se cambia al path de cada platillo que concuerde con menuDetail 

  //sopas example
  "#Sopa_Soi_Kao": {
    "titulo": "Sopa Soi Kao",
    "img": sopasoikao,
    "precio": "Q85.00",
    "tipo": "Sopas",
    "categoria": "Sopas",
    "descripcion": "Deliciosa tela de wantan cocida, con relleno de camarón o lomito, hongos Shiitake, retoño de bambú, acompañado de brócoli.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Sopa_Soi_Kao",
    "variants": ["Camaron", "Lomito"],
    "imgs": [shrimp, steak],
    "descs": ["Camarón Q85.00", "Lomito Q85.00"],
    "name": "Sopas"
  },
  "#Crema_de_Elote": {
    "titulo": "Crema de elote",
    "img": sopacremalomo,
    "precio": "Q45.00",
    "tipo": "Sopas",
    "categoria": "Sopas",
    "descripcion": "Cremosa sopa a base de elote.  Puedes solicitar con trocitos de pollo o lomito por Q5 adicionales.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Crema_de_Elote",
    "variants": ["Lomito", "Pollo"],
    "imgs": [steak, chicken ],
    "descs": ["Lomito Q48.00", "Pollo Q48.00"],
    "name": "Sopas"
  },
  "#Sopa_de_carne_de_Cangrejo": {
    "titulo": "Sopa de carne de cangrejo",
    "img": sopacangrejo,
    "precio": "Q64.00",
    "tipo": "Sopas",
    "categoria": "Sopas",
    "descripcion": "Carne de cangrejo acompañado de carne de cerdo, hongos Shiitake, queso de soya y clara de huevo.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Sopa_de_carne_de_Cangrejo",
    "name": "Sopas"
  },
  "#Sopa_de_Wantan": {
    "titulo": "Sopa de wantan",
    "img": sopawantancamaron,
    "precio": "Q73.00",
    "tipo": "Sopas",
    "categoria": "Sopas",
    "descripcion": "Deliciosos wantan (fritos o cocidos) con carne de camarón o lomito en nuestro delicioso consomé oriental.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Sopa_de_Wantan",
    "variants": ["Camaron", "Lomito"],
    "imgs": [shrimp, steak],
    "descs": ["Camarón Q73.00", "Lomito  Q73.00"],
    "name": "Sopas"
  },
  "#Sopa_variedad_de_Mariscos": {
    "titulo": "Sopa variedad de mariscos",
    "img": sopavariadocamaron,
    "precio": "Q64.00",
    "tipo": "Sopas",
    "categoria": "Sopas",
    "descripcion": "Mezcla de mariscada combinada con mejillón, carne de cangrejo, camarón, champiñones, hongos Shiitake y arveja china, caracterizándose por ser cremosa.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Sopa_variedad_de_Mariscos",
    "name": "Sopas"
  },
  "#Sopa_Variedad_de_Carnes": {
    "titulo": "Sopa variedad de carnes",
    "img": sopavariado,
    "precio": "Q87.00",
    "tipo": "Sopas",
    "categoria": "Sopas",
    "descripcion": "Deliciosa combinación de 5 carnes: pollo, lomito, cerdo, camarón y pato, acompañado de deliciosas verduras: brócoli, apio, arveja china y queso de soya.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Sopa_Variedad_de_Carnes",
    "name": "Sopas"
  },
  "#Sopa_de_Pato": {
    "titulo": "Sopa de pato",
    "img": sopapato,
    "precio": "Q87.00",
    "tipo": "Sopas",
    "categoria": "Sopas",
    "descripcion": "Deliciosos filetes de pato acompañado de hongos Shiitake y bambú en una especial salsa cremosa.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Sopa_de_Pato",
    "name": "Sopas"
  },
  "#Sopa_Picante_al_estilo_Norte": {
    "titulo": "Sopa picante al estilo norte",
    "img": sopanorte,
    "precio": "Q42.00",
    "tipo": "Sopas",
    "categoria": "Sopas",
    "descripcion": "Lo especial de esta sopa es su sabor agridulce con toque de chile jalapeño, trocitos de tomate, queso de soya, acompañando deliciosos camarones y cerdo.  Ideal para una tarde de frío.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Sopa_Picante_al_estilo_Norte",
    "name": "Sopas"
  },
  "#Sopa_Mein_Mixta": {
    "titulo": "Sopa Mein mixta",
    "img": sopameinmixta,
    "precio": "Q89.00",
    "tipo": "Mein",
    "categoria": "Sopas Mein",
    "descripcion": "Sopa de fideo acompañada de pollo, lomito, cerdo, camarón y deliciosa verdura (brócoli, arveja china y apio)",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Sopa_Mein_Mixta",
    "name": "Mein",
    "sauce": ["Si prefieres con fideo de arroz, puedes cambiarlo por Q10 adicionales"]
  },
  "#Sopa_Mein_de_Lomito": {
    "titulo": "Sopa Mein de lomito",
    "img": sopameinlomito,
    "precio": "65.00",
    "tipo": "Mein",
    "categoria": "Sopas Mein",
    "descripcion": "Sopa de fideo acompañada de lomito y deliciosa verdura (brócoli, arveja china y apio)",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Sopa_Mein_de_Lomito",
    "name": "Mein",
    "sauce": ["Si prefieres con fideo de arroz, puedes cambiarlo por Q10 adicionales"]
  },
  "#Sopa_Mein_de_Pollo": {
    "titulo": "Sopa Mein de pollo",
    "img": sopameinpato,
    "precio": "65.00",
    "tipo": "Mein",
    "categoria": "Sopas Mein",
    "descripcion": "Sopa de fideo  acompañada de pollo y deliciosa verdura (brócoli, arveja china y apio)",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Sopa_Mein_de_Pollo",
    "name": "Mein",
    "sauce": ["Si prefieres con fideo de arroz, puedes cambiarlo por Q10 adicionales"]
  },
  "#Sopa_Mein_de_Cerdo": {
    "titulo": "Sopa Mein de cerdo",
    "img": sopameincerdo,
    "precio": "65.00",
    "tipo": "Mein",
    "categoria": "Sopas Mein",
    "descripcion": "Sopa de fideo acompañada de cerdo y deliciosa verdura (brócoli, arveja china y apio)",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Sopa_Mein_de_Cerdo",
    "name": "Mein",
    "sauce": ["Si prefieres con fideo de arroz, puedes cambiarlo por Q10 adicionales"]
  },
  "#Sopa_Mein_de_Camaron": {
    "titulo": "Sopa Mein de camarón",
    "img": sopameincamaron,
    "precio": "Q89.00",
    "tipo": "Mein",
    "categoria": "Sopas Mein",
    "descripcion": "Sopa de fideo acompañada de camarón y deliciosa verdura (brócoli, arveja china y apio)",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Sopa_Mein_de_Camaron",
    "name": "Mein",
    "sauce": ["Si prefieres con fideo de arroz, puedes cambiarlo por Q10 adicionales"]
  },
  "#Sopa_Mein_de_Pato_Pekines_Asado": {
    "titulo": "Sopa Mein de pato asado",
    "img": sopameinpollo,
    "precio": "Q85.00",
    "tipo": "Mein",
    "categoria": "Sopas Mein",
    "descripcion": "Sopa de fideo acompañada de pato y deliciosa verdura (brócoli, arveja china y apio)",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Sopa_Mein_de_Pato_Pekines_Asado",
    "name": "Mein",
    "sauce": ["Si prefieres con fideo de arroz, puedes cambiarlo por Q10 adicionales"]
  },
  "#Sopa_Mein_de_Filete_de_Pescado": {
    "titulo": "Sopa Mein de filete de pescado",
    "img": sopameinpescado,
    "precio": "Q60.00",
    "tipo": "Mein",
    "categoria": "Sopas Mein",
    "descripcion": "Sopa de fideo acompañada de pescado y deliciosa verdura (brócoli, arveja china y apio)",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Sopa_Mein_de_Filete_de_Pescado",
    "name": "Mein",
    "sauce": ["Si prefieres con fideo de arroz, puedes cambiarlo por Q10 adicionales"]
  },
  "#Sopa_Mein_de_Camaron_Grande": {
    "titulo": "Sopa Mein de camarón grande",
    "img": sopameincamaron,
    "precio": "Q175.00",
    "tipo": "Mein",
    "categoria": "Sopas Mein",
    "descripcion": "Sopa de fideo acompañada de camarón grande y deliciosa verdura (brócoli, arveja china y apio)",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Sopa_Mein_de_Camaron_Grande",
    "name": "Mein",
    "sauce": ["Si prefieres con fideo de arroz, puedes cambiarlo por Q10 adicionales"]
  },
  "#Sopa_Mein_Vegetariana": {
    "titulo": "Sopa Mein vegetariana",
    "img": sopaver,
    "precio": "Q53.00",
    "tipo": "Vegetarianos",
    "categoria": "Vegetarianos",
    "descripcion": "Sopa de fideo acompañada de deliciosa verdura (brócoli, arveja china y apio)",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Sopa_Mein_Vegetariana",
    "name": "Mein",
    "sauce": ["Si prefieres con fideo de arroz, puedes cambiarlo por Q10 adicionales"]
  },

  //entradas example
  "#Wantan_Frito": {
    "titulo": "Wantan (10 Unidades)",
    "img": wantan_camaron,
    "precio": "Q65.00",
    "tipo": "Entradas",
    "categoria": "Entradas",
    "descripcion": "Crujiente tela de wantan relleno de masa de camarón o lomito.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Wantan_Frito",
    "variants": ["Camarón", "Lomito"],
    "imgs": [shrimp, steak],
    "descs": ["Camarón Q65.00", "Lomito Q55.00"],
    "name": "Entradas"
  },
  "#Cha_Siu": {
    "titulo": "Cha Siu (Libra)",
    "img": cha_sui,
    "precio": "Q98.00",
    "tipo": "Entradas",
    "categoria": "Entradas",
    "descripcion": "Exquisita carne de cerdo horneada con salsa barbacoa.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Cha_Siu",
    "name": "Entradas"
  },
  "#Cha_Siu-1-2": {
    "titulo": "Cha Siu (1/2 libra)",
    "img": med_cha_sui,
    "precio": "Q55.00",
    "tipo": "Entradas",
    "categoria": "Entradas",
    "descripcion": "Exquisita carne de cerdo horneada con salsa barbacoa.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Cha_Siu-1-2",
    "name": "Entradas"
  },
  "#Tacos_Lai_Lai_(4_Unidades)": {
    "titulo": "Tacos Lai Lai (4 Unidades)",
    "img": tacos_lailai,
    "precio": "47.00",
    "tipo": "Entradas",
    "categoria": "Entradas",
    "descripcion": "Especial combinación de pollo, cerdo y vegetales (apio, cebolla, chile pimiento, retoño de soya), acompañado de nuestra inigualable salsa agridulce.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Tacos_Lai_Lai_(4_Unidades)",
    "name": "Entradas"
  },
  "#Taquitos_de_Pollo_(6_Unidades)": {
    "titulo": "Taquitos de pollo (6 Unidades)",
    "img": tacos_pollo,
    "precio": "42.00",
    "tipo": "Entradas",
    "categoria": "Entradas",
    "descripcion": "Crujientes taquitos rellenos de una deliciosa combinación de pollo con gotitas de picante y cilantro.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Taquitos_de_Pollo_(6_Unidades)",
    "name": "Entradas"
  },
  "#Taquitos_de_Pescado_(6_Unidades)": {
    "titulo": "Taquitos de pescado (6 Unidades)",
    "img": tacos_pescado,
    "precio": "47.00",
    "tipo": "Entradas",
    "categoria": "Entradas",
    "descripcion": "Crujientes taquitos rellenos de pescado con hongo Shiitake y cilantro.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Taquitos_de_Pescado_(6_Unidades)",
    "name": "Entradas"
  },
  "#Taquitos_Mixtos_(3_Pollo_y_3_Pescado)": {
    "titulo": "Taquitos mixtos (3 Pollo y 3 Pescado)",
    "img": tacos_mixto,
    "precio": "47.00",
    "tipo": "Entradas",
    "categoria": "Entradas",
    "descripcion": "3 Crujientes taquitos rellenos de pescado con hongo Shiitake y cilantro y 3  taquitos rellenos de una deliciosa combinación de pollo con gotitas de picante y cilantro.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Taquitos_Mixtos_(3_Pollo_y_3_Pescado)",
    "name": "Entradas"
  },
  "#Sampler_de_Wantan_de_Camaron_y_Taquitos_de_Pollo": {
    "titulo": "Sampler de wantan de camarón y taquitos de pollo",
    "img": sample_camaron,
    "precio": "Q87.00",
    "tipo": "Entradas",
    "categoria": "Entradas",
    "descripcion": "Combinación de Wantan, Taquitos y Cha Siu.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Sampler_de_Wantan_de_Camaron_y_Taquitos_de_Pollo",
    "name": "Entradas"
  },
  "#Pollo_frito_empanizado_con_ajonjoli": {
    "titulo": "Pollo frito empanizado con ajonjolí",
    "img": pollofrito,
    "precio": "Q65.00",
    "tipo": "Entradas",
    "categoria": "Entradas",
    "descripcion": "Trocitos de pollo empanizado con ajonjolí acompañados de nuestra especial salsa agridulce.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Pollo_frito_empanizado_con_ajonjoli",
    "name": "Entradas"
  },
  "#Camarones_Empanizados": {
    "titulo": "Camarones empanizados",
    "img": camaron_empanizado,
    "precio": "Q98.00",
    "tipo": "Entradas",
    "categoria": "Entradas",
    "descripcion": "Crujientes camarones empanizados acompañados de salsa agridulce.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Camarones_Empanizados",
    "name": "Entradas"
  },
  "#Corbatas_(27_Unidades)": {
    "titulo": "Corbatas (27 Unidades)",
    "img": corbatas,
    "precio": "36.00",
    "tipo": "Entradas",
    "categoria": "Entradas",
    "descripcion": "Corbatitas tostadas a base de tela de wantán, acompañadas de nuestra especial salsa agridulce.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Corbatas_(27_Unidades)",
    "name": "Entradas"
  },

  //especialidades example
  "#San_Choy_Pau": {
    "titulo": "San Choy Pau",
    "img": sanchoy,
    "precio": "Q97.00",
    "tipo": "Especialidades",
    "categoria": "Platos Fuertes",
    "descripcion": "Combinación de picadillo de carne a tu elección y verduras (apio, cebolla, chile pimiento, retoño de soya), listo para ser envuelto en fresca lechuga, con toques de fideo de arroz y semilla de marañón. Puedes pedirlo mixto o también puedes cambiarlo a una sola carne eligiendo: camarón, lomito, pollo ó cerdo.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#San_Choy_Pau",
    //extra
    "variants": ["Camarón", "Lomito", "Pollo", "Cerdo", "Mixto"],
    "imgs": [shrimp, steak, chicken, steak, mixto],
    "descs": ["Camarón Q97.00", "Lomito Q97.00", "Pollo Q97.00", "Cerdo Q97.00", "Mixto Q97.00"],
    "name": "Especialidades"
  },
  "#Pato_Entero": {
    "titulo": "Pato entero",
    "img": pato,
    "precio": "Q245.00",
    "tipo": "Especialidades",
    "categoria": "Platos Fuertes",
    "descripcion": "Delicioso pato sazonado con variedad de especias chinas y un cocimiento especial para un horneado único.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Pato_Entero",
    "variants": ["Pato Entero", "Medio Pato"],
    "imgs": [chicken, chicken],
    "descs": ["Pato Entero Q245.00", "Medio Pato Q140.00"],
   
    "name": "Especialidades"
  },
 
  "#Camarones_grandes_al_perol_con_sal_y_pimienta": {
    "titulo": "Camarones grandes al perol con sal y pimienta",
    "img": camaronessalp,
    "precio": "Q175.00",
    "tipo": "Especialidades",
    "categoria": "Platos Fuertes",
    "descripcion": "Exquisitos camarones grandes condimentados con una mezcla de especias chinas, sal y pimienta, al perol.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Camarones_grandes_al_perol_con_sal_y_pimienta",
    "name": "Especialidades"
  },
  "#Filete_de_Pescado_al_vapor": {
    "titulo": "Filete de pescado al vapor",
    "img": perscadoVapor,
    "precio": "Q140.00",
    "tipo": "Especialidades",
    "categoria": "Platos Fuertes",
    "descripcion": "Jugoso filete de róbalo cocinado al vapor, aderezado con cebollita y jengibre a la juliana y salsa de soya. Puedes también solicitarlo flameado (aplica únicamente para comer en Restaurantes)",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Filete_de_Pescado_al_vapor",
    "name": "Especialidades"
  },
  "#Cacerola_variedad_de_carnes": {
    "titulo": "Cacerola variedad de carnes",
    "img": cacerola,
    "precio": "Q105.00",
    "tipo": "Especialidades",
    "categoria": "Platos Fuertes",
    "descripcion": "Variedad de carnes (pollo, lomito, cerdo, camarón y pato) acompañado de fresca verdura (brócoli, apio y arveja china) con una deliciosa salsa Min Si.",
    "sauce": ["Min Si: Salsa a base de pasta de frijol de soya fermentado"],
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Cacerola_variedad_de_carnes",
    "name": "Especialidades"
  },
  "#Berenjenas_doradas_rellenas": {
    "titulo": "Berenjenas doradas rellenas",
    "img": berenrelle,
    "precio": "Q78.00",
    "tipo": "Especialidades",
    "categoria": "Platos Fuertes",
    "descripcion": "Trozos de berenjenas enharinadas rellenas de lomito o camarón acompañadas de nuestra exquisita salsa agridulce.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Berenjenas_doradas_rellenas",
    "variants": ["Camarón", "Lomito"],
    "imgs": [shrimp, steak],
    "descs": ["Camarón Q78.00", "Lomito Q78.00"],
    "name": "Especialidades"
  },
  "#Berenjenas_guisadas_rellenas": {
    "titulo": "Berenjenas guisadas rellenas",
    "img": berenguisado,
    "precio": "Q78.00",
    "tipo": "Especialidades",
    "categoria": "Platos Fuertes",
    "descripcion": "Trozos de berenjenas guisadas rellenas de lomito o camarón acompañadas de nuestra exquisita salsa Tau Si.",
    "sauce": ["Tau Si: a base de frijol de soya fermentado."],
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Berenjenas_guisadas_rellenas",
    "variants": ["Camarón", "Lomito"],
    "imgs": [shrimp, steak],
    "descs": ["Camarón Q78.00", "Lomito Q78.00"],
    "name": "Especialidades"
  },
  "#Chile_jalapeno_dorado_relleno": {
    "titulo": "Chile jalapeño dorado relleno",
    "img": jalaberenrelle,
    "precio": "Q90.00",
    "tipo": "Especialidades",
    "categoria": "Platos Fuertes",
    "descripcion": "Deliciosos chiles jalapeños enharinados rellenos de lomito o camarón acompañados de nuestra exquisita salsa agridulce.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Chile_jalapeno_dorado_relleno",
    "variants": ["Camarón", "Lomito"],
    "imgs": [shrimp, steak],
    "descs": ["Camarón Q90.00", "Lomito Q90.00"],
    "name": "Especialidades"
  },
  "#Chile_jalapeno_guisado_relleno": {
    "titulo": "Chile jalapeño guisado relleno",
    "img": jalapeño,
    "precio": "Q90.00",
    "tipo": "Especialidades",
    "categoria": "Platos Fuertes",
    "descripcion": "Deliciosos chiles jalapeños guisados rellenos de lomito o camarón acompañados de nuestra exquisita salsa Tau Si.",
    "sauce": ["Tau Si: a base de frijol de soya fermentado."],
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Chile_jalapeno_guisado_relleno",
    "variants": ["Camarón", "Lomito"],
    "imgs": [shrimp, steak],
    "descs": ["Camarón Q90.00", "Lomito Q90.00"],
    "name": "Especialidades"
  },
  "#Berenjena_y_chile_jalapeno_guisado_relleno": {
    "titulo": "Berenjena y chile jalapeño guisado relleno",
    "img": guisado,
    "precio": "Q95.00",
    "tipo": "Especialidades",
    "categoria": "Platos Fuertes",
    "descripcion": "Deliciosos trozos de berenjena y chiles jalapeños guisados rellenos de lomito o camarón acompañados de nuestra exquisita salsa Tau Si.",
    "sauce": ["Tau Si: a base de frijol de soya fermentado."],
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Berenjena_y_chile_jalapeno_guisado_relleno",
    "variants": ["Camarón", "Lomito"],
    "imgs": [shrimp, steak],
    "descs": ["Camarón Q95.00", "Lomito Q95.00"],
    "name": "Especialidades"
  },
  "#Berenjena_y_chile_jalapeno_dorados_relleno": {
    "titulo": "Berenjena y chile jalapeño dorados relleno",
    "img": jalaberenrelle,
    "precio": "Q95.00",
    "tipo": "Especialidades",
    "categoria": "Platos Fuertes",
    "descripcion": "Deliciosos trozos de berenjena y chiles jalapeños enharinados rellenos de lomito acompañados de nuestra exquisita salsa agridulce.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Berenjena_y_chile_jalapeno_dorados_relleno",
    "variants": ["Camarón", "Lomito"],
    "imgs": [shrimp, steak],
    "descs": ["Camarón Q95.00", "Lomito Q95.00"],
    "name": "Especialidades"
  },
  "#Hongos_Shiitake_rellenos_de_camaron": {
    "titulo": "Hongos Shiitake rellenos de camarón",
    "img": hongosrellenos,
    "precio": "Q105.00",
    "tipo": "Especialidades",
    "categoria": "Platos Fuertes",
    "descripcion": "Exquisitos hongos Shiitake, rellenos con una masa única de camarón, acompañado de una salsa blanca.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Hongos_Shiitake_rellenos_de_camaron",
    "name": "Especialidades"
  },
  "#Mejillones_al_perol_con_salsa_Tau_Si": {
    "titulo": "Mejillones al perol con salsa Tau Si",
    "img": mejillones,
    "precio": "Q105.00",
    "tipo": "Especialidades",
    "categoria": "Platos Fuertes",
    "descripcion": "Exquisitos mejillones mezclados al perol con chile pimiento, cebollita y chile jalapeño, en una deliciosa salsa Tau Si.",
    "sauce": ["Tau Si: a base de frijol de soya fermentado."],
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Mejillones_al_perol_con_salsa_Tau_Si",
    "name": "Especialidades"
  },
 
  "#Pollo_con_hongo_Sin_Ku": {
    "titulo": "Pollo con hongo Sin Ku",
    "img": sinku,
    "precio": "Q100.00",
    "tipo": "Especialidades",
    "categoria": "Platos Fuertes",
    "descripcion": "Trocitos de pollo con hongos Sin Ku, mezclados con arveja china y cebollita en una deliciosa salsa de ostras.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Pollo_con_hongo_Sin_Ku",
    "name": "Especialidades"
  },
  "#Ma_Po_Tau_Fu": {
    "titulo": "Ma Po To Fu",
    "img": mao,
    "precio": "Q80.00",
    "tipo": "Especialidades",
    "categoria": "Platos Fuertes",
    "descripcion": "Exótica combinación de picadillo de camarón, pollo, cerdo, champiñones, hongo Sin Ku, hongos Shiitake, queso de soya, chile pimiento, chile jalapeño, cebollita, dándole un sabor especial nuestra exquisita salsa Min Si.",
    "sauce": ["Min Si: Salsa a base de pasta de frijol de soya fermentado"],
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Ma_Po_Tau_Fu",
    "variants": ["Grande", "Pequeño"],
    "imgs": [grande, pequeno],
    "descs": ["Grande Q80.00", "Pequeño Q55.00"],
    "name": "Especialidades"
  },
  //tie pan example
  "#Camarones_estilo_Tie_Pan": {
    "titulo": "Camarones grandes estilo Tie Pan (a la plancha)",
    "img": camaronplancha,
    "precio": "Q175.00",
    "tipo": "Tie_Pan",
    "categoria": "Platos Fuertes",
    "path": "#Camarones_estilo_Tie_Pan",
    "descripcion": "Deliciosos camarones grandes al perol sobre cebolla, chile pimiento, retoño de soya y apio en julianas, con la salsa de su elección.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "sauce": ["Salsa curry", " Salsa encebollada", "Salsa de ajo", "Salsa de ajo con verduras", "Salsa de ostras", "Salsa de soya", "Salsa agridulce"],
    "sauce_desc": ["Combinación de especias picantes junto con cebolla y chile pimiento.", "Salsa de soya con cebolla.", "Salsa de ajo.", "Salsa de ajo con verduras (apio, arveja china y brócoli).", "Salsa de ostras con verduras (apio, arveja y brócoli).", "Salsa de soya con chile pimiento y cebolla.", "Salsa agridulce con cebolla, chile pimiento y piña"],
    "name": "Tie Pan"
  },
  "#Variedad_de_carnes_estilo_Tie_Pan": {
    "titulo": "Variedad de carnes estilo Tie Pan (a la plancha)",
    "img": carnevariedad,
    "precio": "Q105.00",
    "tipo": "Tie_Pan",
    "categoria": "Platos Fuertes",
    "path": "#Variedad_de_carnes_estilo_Tie_Pan",
    "descripcion": "Combinación de pollo, lomito y camarón, sobre cebolla, chile pimiento, retoño de soya y apio en julianas, con la salsa de su elección.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "sauce": ["Salsa curry", " Salsa encebollada", "Salsa de ajo", "Salsa de ajo con verduras", "Salsa de ostras", "Salsa de soya", "Salsa agridulce"],
    "name": "Tie Pan"
  },
  "#Lomito_estilo_Tie_Pan": {
    "titulo": "Lomito estilo Tie Pan (a la plancha)",
    "img": lomoplancha,
    "precio": "Q93.00",
    "tipo": "Tie_Pan",
    "categoria": "Platos Fuertes",
    "path": "#Lomito_estilo_Tie_Pan",
    "descripcion": "Jugoso lomito sobre cebolla, chile pimiento, retoño de soya y apio en julianas, con la salsa de su elección.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "sauce": ["Salsa curry", " Salsa encebollada", "Salsa de ajo", "Salsa de ajo con verduras", "Salsa de ostras", "Salsa de soya", "Salsa agridulce"],
    "name": "Tie Pan"
  },
  "#Pollo_estilo_Tie_Pan": {
    "titulo": "Pollo estilo Tie Pan (a la plancha)",
    "img": pollolancha,
    "precio": "Q82.00",
    "tipo": "Tie_Pan",
    "categoria": "Platos Fuertes",
    "path": "#Pollo_estilo_Tie_Pan",
    "descripcion": "Exquisitos trocitos de pollo colocado sobre cebolla, chile pimiento, retoño de soya y apio en julianas, con la salsa de su elección.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "sauce": ["Salsa curry", " Salsa encebollada", "Salsa de ajo", "Salsa de ajo con verduras", "Salsa de ostras", "Salsa de soya", "Salsa agridulce"],
    "name": "Tie Pan"
  },
  "#Costillas_de_cerdo_estilo_Tie_Pan": {
    "titulo": "Costillas de cerdo estilo Tie Pan (a la plancha)",
    "img": costillalancha,
    "precio": "Q69.00",
    "tipo": "Tie_Pan",
    "categoria": "Platos Fuertes",
    "path": "#Costillas_de_cerdo_estilo_Tie_Pan",
    "descripcion": "Costillas empanizadas de marrano a la plancha, sobre cebolla, chile pimiento, retoño de soya y apio en julianas, con la salsa de su elección.  Incluye porción de arroz blanco.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "sauce": ["Salsa curry", " Salsa encebollada", "Salsa de ajo", "Salsa de ajo con verduras", "Salsa de ostras", "Salsa de soya", "Salsa agridulce"],
    "name": "Tie Pan"
  },
  "#Caracoles_estilo_Tie_Pan": {
    "titulo": "Caracoles con verduras",
    "img": caracolplancha,
    "precio": "Q140.00",
    "tipo": "Pescados-Mariscos",
    "categoria": "Platos Fuertes",
    "path": "#Caracoles_estilo_Tie_Pan",
    "descripcion": "Caracoles con verduras (arveja china, apio y brócoli) acompañado de una deliciosa salsa de ostras.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "name": "Mariscos"
  },
  "#Caracoles_con_verduras_estilo_Tie_Pan": {
    "titulo": "Caracoles con verduras estilo Tie Pan (a la plancha)",
    "img": caracol,
    "precio": "Q140.00",
    "tipo": "Tie_Pan",
    "categoria": "Platos Fuertes",
    "path": "#Caracoles_con_verduras_estilo_Tie_Pan",
    "descripcion": "Caracoles con verduras (arveja china, apio y brócoli) acompañado de la salsa a su elección.",
    "sauce": ["Salsa curry", " Salsa encebollada", "Salsa de ajo", "Salsa de ajo con verduras", "Salsa de ostras", "Salsa de soya", "Salsa agridulce"],
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "name": "Tie Pan"
  },
  "#Calamares_con_verduras_estilo_Tie_Pan": {
    "titulo": "Calamares con verduras estilo Tie Pan (a la plancha)",
    "img": calamar,
    "precio": "Q120.00",
    "tipo": "Tie_Pan",
    "categoria": "Platos Fuertes",
    "path": "#Calamares_con_verduras_estilo_Tie_Pan",
    "descripcion": "Exquisitos calamares colocado sobre cebolla, chile pimiento, retoño de soya y apio en julianas, con la salsa de su elección",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "sauce": ["Salsa curry", " Salsa encebollada", "Salsa de ajo", "Salsa de ajo con verduras", "Salsa de ostras", "Salsa de soya", "Salsa agridulce"],
    "name": "Tie Pan"
  },

  //chaw_mean-arroz example
  "#arroz_mixto": {
    "titulo": "Arroz mixto",
    "img": arrozmixto,
    "precio": "Q69.00",
    "tipo": "chaw_mean-arroz",
    "categoria": "Platos Fuertes",
    "path": "#arroz_mixto",
    "tiempo": "30-45 min",
    "descripcion": "Exquisito arroz frito acompañado de lomito, camarón, pollo, cerdo, combinado con lechuga, cebollita y huevo.",
    "name": "Chaw Mein/Arroz Frito"
  },
  "#Arroz_de_camaron": {
    "titulo": "Arroz de camarón",
    "img": arrozcama,
    "precio": "Q75.00",
    "tipo": "chaw_mean-arroz",
    "categoria": "Platos Fuertes",
    "path": "#Arroz_de_camaron",
    "tiempo": "30-45 min",
    "descripcion": "Exquisito arroz frito acompañado de camarón, combinado con lechuga, cebollita y huevo.",
    "name": "Chaw Mein/Arroz Frito"
  },
  "#Arroz_de_camaron_grande": {
    "titulo": "Arroz de camarón grande",
    "img": arrozcamagrande,
    "precio": "Q175.00",
    "tipo": "chaw_mean-arroz",
    "categoria": "Platos Fuertes",
    "path": "#Arroz_de_camaron_grande",
    "tiempo": "30-45 min",
    "descripcion": "Exquisito arroz frito acompañado de camarón grande, combinado con lechuga, cebollita y huevo.",
    "name": "Chaw Mein/Arroz Frito"
  },
  "#Arroz_de_pollo": {
    "titulo": "Arroz de pollo",
    "img": arrozpollo,
    "precio": "Q64.00",
    "tipo": "chaw_mean-arroz",
    "categoria": "Platos Fuertes",
    "path": "#Arroz_de_pollo",
    "tiempo": "30-45 min",
    "descripcion": "Exquisito arroz frito acompañado de pollo, combinado con lechuga, cebollita y huevo.",
    "name": "Chaw Mein/Arroz Frito"
  },
  "#Arroz_de_lomito": {
    "titulo": "Arroz de lomito",
    "img": arrozlomito,
    "precio": "Q64.00",
    "tipo": "chaw_mean-arroz",
    "categoria": "Platos Fuertes",
    "path": "#Arroz_de_lomito",
    "tiempo": "30-45 min",
    "descripcion": "Exquisito arroz frito acompañado de lomito, combinado con lechuga, cebollita y huevo.",
    "name": "Chaw Mein/Arroz Frito"
  },
  "#Arroz_de_cerdo": {
    "titulo": "Arroz de cerdo",
    "img": arrozcerdo,
    "precio": "Q64.00",
    "tipo": "chaw_mean-arroz",
    "categoria": "Platos Fuertes",
    "path": "#Arroz_de_cerdo",
    "tiempo": "30-45 min",
    "descripcion": "Exquisito arroz frito acompañado de cerdo, combinado con lechuga, cebollita y huevo.",
    "name": "Chaw Mein/Arroz Frito"
  },
  "#Arroz_vegetariano": {
    "titulo": "Arroz vegetariano",
    "img": arrozvege,
    "precio": "Q53.00",
    "tipo": "chaw_mean-arroz",
    "categoria": "Platos Fuertes",
    "path": "#Arroz_vegetariano",
    "tiempo": "30-45 min",
    "descripcion": "Exquisito arroz frito acompañado de lechuga, cebollita, huevo, retoño de soya, apio y chile pimiento.",
    "name": "Chaw Mein/Arroz Frito"
  },
  "#Chaw_Mein_mixto": {
    "titulo": "Chaw Mein mixto",
    "img": chawmixto,
    "precio": "Q69.00",
    "tipo": "chaw_mean-arroz",
    "categoria": "Platos Fuertes",
    "path": "#Chaw_Mein_mixto",
    "tiempo": "30-45 min",
    "descripcion": "¡Delicioso y jugoso! Fideos artesanales mezclados con lomito, pollo, camarón, cerdo y verduras (apio, cebolla, chile pimiento y retoño de soya) en nuestra exquisita salsa de soya de la casa.",
    "name": "Chaw Mein/Arroz Frito",
    "sauce": ["Puedes cambiarlo a Lo Mein (fideo artesanal combinado con verduras y carnes -no jugoso-) sin cargo adicional", "Chaw Mein estilo Hong Kong (fideo artesanal crujiente y frito) por Q8 adicionales", "Si prefieres con fideo de arroz, puedes cambiarlo por Q10 adicionales"]
  },
  "#Chaw_Mein_de_Camaron": {
    "titulo": "Chaw Mein de camarón",
    "img": chawcama,
    "precio": "Q75.00",
    "tipo": "chaw_mean-arroz",
    "categoria": "Platos Fuertes",
    "path": "#Chaw_Mein_de_Camaron",
    "tiempo": "30-45 min",
    "descripcion": "¡Delicioso y jugoso! Fideos artesanales mezclados con camarones y verduras (apio, cebolla, chile pimiento y retoño de soya) en nuestra exquisita salsa de soya de la casa.",
    "name": "Chaw Mein/Arroz Frito",
    "sauce": ["Puedes cambiarlo a Lo Mein (fideo artesanal combinado con verduras y carnes -no jugoso-) sin cargo adicional", "Chaw Mein estilo Hong Kong (fideo artesanal crujiente y frito) por Q8 adicionales", "Si prefieres con fideo de arroz, puedes cambiarlo por Q10 adicionales"]

  },
  "#Chaw_Mein_de_Camaron_Grande": {
    "titulo": "Chaw Mein de camarón grande",
    "img": chawcagrande,
    "precio": "Q175.00",
    "tipo": "chaw_mean-arroz",
    "categoria": "Platos Fuertes",
    "path": "#Chaw_Mein_de_Camaron_Grande",
    "tiempo": "30-45 min",
    "descripcion": "¡Delicioso y jugoso! Fideos artesanales mezclados con camarones grandes y verduras (apio, cebolla, chile pimiento y retoño de soya) en nuestra exquisita salsa de soya de la casa.",
    "name": "Chaw Mein/Arroz Frito",
    "sauce": ["Puedes cambiarlo a Lo Mein (fideo artesanal combinado con verduras y carnes -no jugoso-) sin cargo adicional", "Chaw Mein estilo Hong Kong (fideo artesanal crujiente y frito) por Q8 adicionales", "Si prefieres con fideo de arroz, puedes cambiarlo por Q10 adicionales"]

  },
  "#Chaw_Mein_de_Pollo": {
    "titulo": "Chaw Mein de pollo",
    "img": chawpollo,
    "precio": "64.00",
    "tipo": "chaw_mean-arroz",
    "categoria": "Platos Fuertes",
    "path": "#Chaw_Mein_de_Pollo",
    "tiempo": "30-45 min",
    "descripcion": "¡Delicioso y jugoso! Fideos artesanales mezclados con pollo y verduras (apio, cebolla, chile pimiento y retoño de soya) en nuestra exquisita salsa de soya de la casa.",
    "name": "Chaw Mein/Arroz Frito",
    "sauce": ["Puedes cambiarlo a Lo Mein (fideo artesanal combinado con verduras y carnes -no jugoso-) sin cargo adicional", "Chaw Mein estilo Hong Kong (fideo artesanal crujiente y frito) por Q8 adicionales", "Si prefieres con fideo de arroz, puedes cambiarlo por Q10 adicionales"]

  },
  "#Chaw_Mein_de_Lomito": {
    "titulo": "Chaw Mein de lomito",
    "img": chawmlomito,
    "precio": "Q64.00",
    "tipo": "chaw_mean-arroz",
    "categoria": "Platos Fuertes",
    "path": "#Chaw_Mein_de_Lomito",
    "tiempo": "30-45 min",
    "descripcion": "¡Delicioso y jugoso! Fideos artesanales mezclados con lomito y verduras (apio, cebolla, chile pimiento y retoño de soya) en nuestra exquisita salsa de soya de la casa.",
    "name": "Chaw Mein/Arroz Frito",
    "sauce": ["Puedes cambiarlo a Lo Mein (fideo artesanal combinado con verduras y carnes -no jugoso-) sin cargo adicional", "Chaw Mein estilo Hong Kong (fideo artesanal crujiente y frito) por Q8 adicionales", "Si prefieres con fideo de arroz, puedes cambiarlo por Q10 adicionales"]

  },
  "#Chaw_Mein_de_Cerdo": {
    "titulo": "Chaw Mein de cerdo",
    "img": chawcerdo,
    "precio": "64.00",
    "tipo": "chaw_mean-arroz",
    "categoria": "Platos Fuertes",
    "path": "#Chaw_Mein_de_Cerdo",
    "tiempo": "30-45 min",
    "descripcion": "¡Delicioso y jugoso! Fideos artesanales mezclados con cerdo y verduras (apio, cebolla, chile pimiento y retoño de soya) en nuestra exquisita salsa de soya de la casa.",
    "name": "Chaw Mein/Arroz Frito",
    "sauce": ["Puedes cambiarlo a Lo Mein (fideo artesanal combinado con verduras y carnes -no jugoso-) sin cargo adicional", "Chaw Mein estilo Hong Kong (fideo artesanal crujiente y frito) por Q8 adicionales", "Si prefieres con fideo de arroz, puedes cambiarlo por Q10 adicionales"]

  },
  "#Chaw_Mein_Vegetariano": {
    "titulo": "Chaw Mein vegetariano",
    "img": chawvege,
    "precio": "Q53.00",
    "tipo": "Vegetarianos",
    "categoria": "Platos Fuertes",
    "path": "#Chaw_Mein_Vegetariano",
    "tiempo": "30-45 min",
    "descripcion": "¡Delicioso y jugoso! Fideos artesanales mezclados con verduras (apio, cebolla, chile pimiento y retoño de soya) en nuestra exquisita salsa de soya de la casa.",
    "name": "Vegetarianos",
    "sauce": ["Puedes cambiarlo a Lo Mein (fideo artesanal combinado con verduras y carnes -no jugoso-) por Q8 adicionales", "Chaw Mein estilo Hong Kong (fideo artesanal crujiente y frito) por Q8 adicionales", "Si prefieres con fideo de arroz, puedes cambiarlo por Q10 adicionales"]

  },
 
 
  "#Fideo_de_arroz_con_curry_picante_mixto": {
    "titulo": "Fideo de arroz con curry picante mixto",
    "img": famixto,
    "precio": "Q75.00",
    "tipo": "chaw_mean-arroz",
    "categoria": "Platos Fuertes",
    "path": "#Fideo_de_arroz_con_curry_picante_mixto",
    "tiempo": "30-45 min",
    "descripcion": "Fideo de arroz con curry picante acompañado de pollo, lomito, cerdo, camarón y verduras (apio, chile pimiento, cebolla y retoño de soya).",
    "name": "Chaw Mein/Arroz Frito",

  },
  "#Fideo_de_arroz_con_curry_picante_camaron": {
    "titulo": "Fideo de arroz con curry picante camarón",
    "img": facamaron,
    "precio": "Q85.00",
    "tipo": "chaw_mean-arroz",
    "categoria": "Platos Fuertes",
    "path": "#Fideo_de_arroz_con_curry_picante_camaron",
    "tiempo": "30-45 min",
    "descripcion": "Fideo de arroz con curry picante acompañado de camarón y verduras (apio, chile pimiento, cebolla y retoño de soya).",
    "name": "Chaw Mein/Arroz Frito",

  },
  "#Fideo_de_arroz_con_curry_picante_pollo": {
    "titulo": "Fideo de arroz con curry picante pollo",
    "img": fapollo,
    "precio": "Q66.00",
    "tipo": "chaw_mean-arroz",
    "categoria": "Platos Fuertes",
    "path": "#Fideo_de_arroz_con_curry_picante_pollo",
    "tiempo": "30-45 min",
    "descripcion": "Fideo de arroz con curry picante acompañado de pollo y verduras (apio, chile pimiento, cebolla y retoño de soya).",
    "name": "Chaw Mein/Arroz Frito",
  
  },
  "#Fideo_de_arroz_con_curry_picante_lomito": {
    "titulo": "Fideo de arroz con curry picante lomito",
    "img": falomito,
    "precio": "Q66.00",
    "tipo": "chaw_mean-arroz",
    "categoria": "Platos Fuertes",
    "path": "#Fideo_de_arroz_con_curry_picante_lomito",
    "tiempo": "30-45 min",
    "descripcion": "Fideo de arroz con curry picante acompañado de lomito y verduras (apio, chile pimiento, cebolla y retoño de soya).",
    "name": "Chaw Mein/Arroz Frito",

  },
  "#Fideo_de_arroz_con_curry_picante_cerdo": {
    "titulo": "Fideo de arroz con curry picante cerdo",
    "img": facerdo,
    "precio": "Q66.00",
    "tipo": "chaw_mean-arroz",
    "categoria": "Platos Fuertes",
    "path": "#Fideo_de_arroz_con_curry_picante_cerdo",
    "tiempo": "30-45 min",
    "descripcion": "Fideo de arroz con curry picante acompañado de cerdo y verduras (apio, chile pimiento, cebolla y retoño de soya).",
    "name": "Chaw Mein/Arroz Frito",

  },

  //chop suey example
  "#Chop_suey_Mixto": {
    "titulo": "Chop Suey mixto",
    "img": chopmixto,
    "precio": "Q105.00",
    "tipo": "Chop_suey",
    "categoria": "Platos Fuertes",
    "path": "#Chop_suey_Mixto",
    "tiempo": "30-45 min",
    "descripcion": "Delicioso pollo, lomito, cerdo y camarón peroleados con apio cebolla, champiñones y chile pimiento, dándole un toque especial con semilla de marañón.",
    "name": "Chop Suey"
  },
  "#Chop_suey_Camaron": {
    "titulo": "Chop Suey camarón",
    "img": chopcama,
    "precio": "Q105.00",
    "tipo": "Chop_suey",
    "categoria": "Platos Fuertes",
    "path": "#Chop_suey_Camaron",
    "tiempo": "30-45 min",
    "descripcion": "Deliciosos camarones peroleados con apio cebolla, champiñones y chile pimiento, dándole un toque especial con semilla de marañón.",
    "name": "Chop Suey"
  },
  "#Chop_suey_con_Lomito": {
    "titulo": "Chop Suey con lomito",
    "img": choplomo,
    "precio": "Q75.00",
    "tipo": "Chop_suey",
    "categoria": "Platos Fuertes",
    "path": "#Chop_suey_con_Lomito",
    "tiempo": "30-45 min",
    "descripcion": "Deliciosos lomito peroleado con apio cebolla, champiñones y chile pimiento, dándole un toque especial con semilla de marañón.",
    "name": "Chop Suey"
  },
  "#Chop_suey_con_Pollo": {
    "titulo": "Chop Suey con pollo",
    "img": choppollo,
    "precio": "Q70.00",
    "tipo": "Chop_suey",
    "categoria": "Platos Fuertes",
    "path": "#Chop_suey_con_Pollo",
    "tiempo": "30-45 min",
    "descripcion": "Deliciosos trocitos de pollo peroleados con apio cebolla, champiñones y chile pimiento, dándole un toque especial con semilla de marañón.",
    "name": "Chop Suey"
  },
  "#Chop_suey_con_Cerdo": {
    "titulo": "Chop Suey con cerdo",
    "img": chopcerdo,
    "precio": "Q70.00",
    "tipo": "Chop_suey",
    "categoria": "Platos Fuertes",
    "path": "#Chop_suey_con_Cerdo",
    "tiempo": "30-45 min",
    "descripcion": "Deliciosos trocitos de cerdo peroleados con apio cebolla, champiñones y chile pimiento, dándole un toque especial con semilla de marañón.",
    "name": "Chop Suey"
  },
  "#Lomito_Con_verduras": {
    "titulo": "Lomito con verduras",
    "img": lomover,
    "precio": "Q79.00",
    "tipo": "Carnes",
    "categoria": "Platos Fuertes",
    "path": "#Lomito_Con_verduras",
    "tiempo": "30-45 min",
    "descripcion": "Delicioso lomito con brócoli, apio y arveja china, bañado en una exquisita salsa a tu elección.  Puedes escoger salsa de soya o salsa de ostras.",
    "name": "Carnes"
  },
  "#Lomito_Con_Tau_Si_y_chile_pimiento": {
    "titulo": "Lomito Con Tau Si y chile pimiento",
    "img": lomotau,
    "precio": "Q79.00",
    "tipo": "Carnes",
    "categoria": "Platos Fuertes",
    "path": "#Lomito_Con_Tau_Si_y_chile_pimiento",
    "tiempo": "30-45 min",
    "descripcion": "Delicioso lomito con chile pimiento y cebolla, en una exquisita salsa Tau Si.",
    "sauce": ["Tau Si: a base de frijol de soya fermentado."],
    "name": "Carnes"
  },
  "#Lomito_Con_hongos_Shiitake_y_salsa_de_ostras": {
    "titulo": "Lomito con hongos Shiitake y salsa de ostras",
    "img": lomohongos,
    "precio": "Q85.00",
    "tipo": "Carnes",
    "categoria": "Platos Fuertes",
    "path": "#Lomito_Con_hongos_Shiitake_y_salsa_de_ostras",
    "tiempo": "30-45 min",
    "descripcion": "Exquisito lomito con hongos Shiitake, acompañado de apio, brócoli y arveja china, todo en una exquisita salsa de ostras.",
    "name": "Carnes"
  },
  "#Lomito_En_salsa_curry": {
    "titulo": "Lomito en salsa curry",
    "img": lomocurry,
    "precio": "Q79.00",
    "tipo": "Carnes",
    "categoria": "Platos Fuertes",
    "path": "#Lomito_En_salsa_curry",
    "tiempo": "30-45 min",
    "descripcion": "Exquisito lomito acompañado de cebolla y chile pimiento, todo en una deliciosa salsa curry.",
    "name": "Carnes"
  },
  "#Lomito_A_la_Egg_Foo_Yong": {
    "titulo": "Lomito a la Egg Foo Yong",
    "img": lomoegg,
    "precio": "Q79.00",
    "tipo": "Carnes",
    "categoria": "Platos Fuertes",
    "path": "#Lomito_A_la_Egg_Foo_Yong",
    "tiempo": "30-45 min",
    "descripcion": "Rica combinación de lomito con cebolla, mezclado con huevo y bañando con salsa de ostras con finas lascas de hongos Shiitake.",
    "name": "Carnes"
  },
  "#Lomito_con_Pina": {
    "titulo": "Lomito con piña",
    "img": lomopina,
    "precio": "Q79.00",
    "tipo": "Carnes",
    "categoria": "Platos Fuertes",
    "path": "#Lomito_con_Pina",
    "tiempo": "30-45 min",
    "descripcion": "Delicioso lomito con piña, acompañado de cebolla, chile pimiento y piña, todo en una exquisita salsa agridulce.",
    "name": "Carnes"
  },
  "#Pollo_Con_hongos_Shiitake_en_salsa_de_ostras": {
    "titulo": "Pollo Con hongos Shiitake en salsa de ostras",
    "img": pollohongos,
    "precio": "Q82.00",
    "tipo": "Carnes",
    "categoria": "Platos Fuertes",
    "path": "#Pollo_Con_hongos_Shiitake_en_salsa_de_ostras",
    "tiempo": "30-45 min",
    "descripcion": "Jugosos trocitos de pollo mezclado con brócoli, arveja china, apio y hongos Shiitake, todo en una deliciosa salsa de ostras.",
    "name": "Carnes"
  },
  "#Pollo_Con_pina": {
    "titulo": "Pollo con piña",
    "img": pollopina,
    "precio": "Q65.00",
    "tipo": "Carnes",
    "categoria": "Platos Fuertes",
    "path": "#Pollo_Con_pina",
    "tiempo": "30-45 min",
    "descripcion": "Jugosos trocitos de pollo con piña, combinados con chile pimiento y cebolla, bañados en una exquisita salsa agridulce.",
    "name": "Carnes"
  },
  "#Pollo_en_salsa_curry": {
    "titulo": "Pollo en salsa curry",
    "img": pollocurry,
    "precio": "Q65.00",
    "tipo": "Carnes",
    "categoria": "Platos Fuertes",
    "path": "#Pollo_en_salsa_curry",
    "tiempo": "30-45 min",
    "descripcion": "Jugosos trocitos de pollo mezclados con cebolla y chile pimiento, en una deliciosa salsa curry. (contiene lácteos)",
    "name": "Carnes"
  },
  "#Pollo_Con_verduras": {
    "titulo": "Pollo con verduras",
    "img": pollover,
    "precio": "Q65.00",
    "tipo": "Carnes",
    "categoria": "Platos Fuertes",
    "path": "#Pollo_Con_verduras",
    "tiempo": "30-45 min",
    "descripcion": "Delicioso pollo con brócoli, apio y arveja china, bañado en una exquisita salsa a tu elección.  Puedes escoger salsa de soya o salsa de ostras.",
    "name": "Carnes"
  },
  "#Pollo_Chengzhi": {
    "titulo": "Pollo Chengzhí",
    "img": pollochen,
    "precio": "69.00",
    "tipo": "Carnes",
    "categoria": "Platos Fuertes",
    "path": "#Pollo_Chengzhi",
    "tiempo": "30-45 min",
    "descripcion": "Jugosos trocitos de pollo empanizado cubiertos con una exquisita salsa de naranja.",
    "name": "Carnes"
  },

  //falta imagen
  "#Pato_Guisado_con_hongos_Shiitake": {
    "titulo": "Pato guisado con hongos Shiitake",
    "img": patohongos,
    "precio": "Q107.00",
    "tipo": "Carnes",
    "categoria": "Platos Fuertes",
    "path": "#Pato_Guisado_con_hongos_Shiitake",
    "tiempo": "30-45 min",
    "descripcion": "Deliciosos trozos de pato con brócoli, arveja china, apio y exquisitos hongos Shiitake, todo en una rica salsa de ostras.",
    "name": "Carnes"
  },
  "#Pato_Con_pina": {
    "titulo": "Pato con piña",
    "img": patopina,
    "precio": "Q95.00",
    "tipo": "Carnes",
    "categoria": "Platos Fuertes",
    "path": "#Pato_Con_pina",
    "tiempo": "30-45 min",
    "descripcion": "Deliciosos trozos de pato con piña, combinado con cebolla y chile pimiento en nuestra exquisita salsa agridulce.",
    "name": "Carnes"
  },
  "#Pato_Con_verduras": {
    "titulo": "Pato con verduras",
    "img": patover,
    "precio": "Q95.00",
    "tipo": "Carnes",
    "categoria": "Platos Fuertes",
    "path": "#Pato_Con_verduras",
    "tiempo": "30-45 min",
    "descripcion": "Deliciosos trozos de pato con brócoli, apio y arveja china aderezados con salsa de ostras.",
    "name": "Carnes"
  },
  "#Cha_Siu_Con_verduras": {
    "titulo": "Cha Siu con verduras",
    "img": chasiuver,
    "precio": "Q79.00",
    "tipo": "Carnes",
    "categoria": "Platos Fuertes",
    "path": "#Cha_Siu_Con_verduras",
    "tiempo": "30-45 min",
    "descripcion": "Inigualable Cha Siu (carne de cerdo en barbacoa) con apio, brócoli y arveja china, con un toque de salsa de soya.",
    "name": "Carnes"
  },
  "#Cha_Siu_Con_hongos_Shiitake": {
    "titulo": "Cha Siu con hongos Shiitake",
    "img": chasiuHONGOS,
    "precio": "Q85.00",
    "tipo": "Carnes",
    "categoria": "Platos Fuertes",
    "path": "#Cha_Siu_Con_hongos_Shiitake",
    "tiempo": "30-45 min",
    "descripcion": "Inigualable Cha Siu (carne de cerdo en barbacoa) con hongos Shiitake, acompañado de apio, brócoli y arveja china en una deliciosa salsa de ostras.",
    "name": "Carnes"
  },
  "#Costilla_sazonada_con_sal_y_pimienta": {
    "titulo": "Costilla sazonada con sal y pimienta",
    "img": costiilasazo,
    "precio": "Q62.00",
    "tipo": "Carnes",
    "categoria": "Platos Fuertes",
    "path": "#Costilla_sazonada_con_sal_y_pimienta",
    "tiempo": "30-45 min",
    "descripcion": "Exquisitas costillas sazonadas con sal y pimienta, acompañadas de chile pimiento.",
    "name": "Carnes"
  },
  "#Lomo_de_cerdo_empanizado_con_pina": {
    "titulo": "Lomo de cerdo empanizado con piña",
    "img": lomoCerdoPina,
    "precio": "Q61.00",
    "tipo": "Carnes",
    "categoria": "Platos Fuertes",
    "path": "#Lomo_de_cerdo_empanizado_con_pina",
    "tiempo": "30-45 min",
    "descripcion": "Delicioso lomo de cerdo empanizado, acompañado de cebolla, chile pimiento y piña, todo en una exquisita salsa agridulce.",
    "name": "Carnes"
  },
  "#Fa_San_Kai": {
    "titulo": "Fa San Kai",
    "img": fasankai,
    "precio": "Q70.00",
    "tipo": "Carnes",
    "categoria": "Platos Fuertes",
    "path": "#Fa_San_Kai",
    "tiempo": "30-45 min",
    "descripcion": "Deliciosa combinación de crujientes verduras acompañadas de pollo, cubierto en una especial salsa de maní, con notas cítricas y especiadas",
    "variants": ["Camaron", "Lomito", "Cerdo"],
    "imgs": [shrimp, steak, steak],
    "descs": ["Camarón Q105.00", "Lomito  Q75.00", "Cerdo Q70.00"],
    "name": "Carnes"
  },
  

  //Pescados-Mariscos example
  "#Pescado_con_verduras": {
    "titulo": "Pescado con verduras",
    "img": pescadover,
    "precio": "Q77.00",
    "tipo": "Pescados-Mariscos",
    "categoria": "Platos Fuertes",
    "path": "#Pescado_con_verduras",
    "tiempo": "30-45 min",
    "descripcion": "Deliciosos trocitos de pescado con brócoli, arveja china y apio.",
    "name": "Mariscos"
  },
  "#pescado_empanizado_con_salsa_soya": {
    "titulo": "Pescado empanizado con salsa de soya",
    "img": filete,
    "precio": "Q78.00",
    "tipo": "Pescados-Mariscos",
    "categoria": "Platos Fuertes",
    "tiempo": "30-45 min",
    "descripcion": "Delicioso pescado empanizado bañado en salsa de soya, con Cha Siu y hongo Shiitake, en finos filetes.",
    "path": "#pescado_empanizado_con_salsa_soya",
    "name": "Mariscos"
  },
  "#Filete_pescado_frito_trocitios_agridulce": {
    "titulo": "Filete de pescado frito en trocitos con salsa agridulce",
    "img": pescadoempa,
    "precio": "Q77.00",
    "tipo": "Pescados-Mariscos",
    "categoria": "Platos Fuertes",
    "path": "#Filete_pescado_frito_trocitios_agridulce",
    "tiempo": "30-45 min",
    "descripcion": "Delicioso pescado empanizado acompañado de salsa agridulce. (Salsa se sirve separado).",
    "name": "Mariscos"
  },
  "#Camarones_con_verduras": {
    "titulo": "Camarones con verduras",
    "img": camver,
    "precio": "Q98.00",
    "tipo": "Pescados-Mariscos",
    "categoria": "Platos Fuertes",
    "path": "#Camarones_con_verduras",
    "tiempo": "30-45 min",
    "descripcion": "Frescos camarones con brócoli, arveja china y apio.",
    "name": "Mariscos"
  },
  "#Camarones_a_la_Egg_Foo_Yong": {
    "titulo": "Camarones a la Egg Foo Yong",
    "img": camaegg,
    "precio": "Q98.00",
    "tipo": "Pescados-Mariscos",
    "categoria": "Platos Fuertes",
    "path": "#Camarones_a_la_Egg_Foo_Yong",
    "tiempo": "30-45 min",
    "descripcion": "Frescos camarones con cebolla en juliana, hongos Shiitake y huevo, todo en una exquisita salsa de ostras.",
    "name": "Mariscos"
  },
  "#Camarones_con_verduras_con_salsa_de_ostras": {
    "titulo": "Camarones con verduras con salsa de ostras",
    "img": camsoya,
    "precio": "Q98.00",
    "tipo": "Pescados-Mariscos",
    "categoria": "Platos Fuertes",
    "path": "#Camarones_con_verduras_con_salsa_de_ostras",
    "tiempo": "30-45 min",
    "descripcion": "Frescos camarones con brócoli, arveja china y apio, combinado con una deliciosa salsa de ostras.",
    "name": "Mariscos"
  },
  "#Camarones_con_Tau_Si_y_chile_pimiento": {
    "titulo": "Camarones con Tau Si y chile pimiento",
    "img": camtau,
    "precio": "Q98.00",
    "tipo": "Pescados-Mariscos",
    "categoria": "Platos Fuertes",
    "path": "#Camarones_con_Tau_Si_y_chile_pimiento",
    "tiempo": "30-45 min",
    "descripcion": "Frescos camarones con cebolla, chile pimiento, todo en una exquisita salsa Tau Si.",
    "name": "Mariscos",
    "sauce":["Tau Si: a base de frijol de soya fermentado."]
  },
  "#Camarones_grandes_con_ajo": {
    "titulo": "Camarones grandes con ajo",
    "img": camgrande,
    "precio": "Q175.00",
    "tipo": "Pescados-Mariscos",
    "categoria": "Platos Fuertes",
    "path": "#Camarones_grandes_con_ajo",
    "tiempo": "30-45 min",
    "descripcion": "Deliciosos camarones grandes con ajo.",
    "name": "Mariscos"
  },

  //vegetariano example
  "#Hongo_Shiitake_en_salsa_de_ostras_y_verduras": {
    "titulo": "Hongo Shiitake en salsa de ostras y verduras",
    "img": hongovege,
    "precio": "Q93.00",
    "tipo": "Vegetarianos",
    "categoria": "Platos Fuertes",
    "path": "#Hongo_Shiitake_en_salsa_de_ostras_y_verduras",
    "tiempo": "30-45 min",
    "descripcion": "Exquisitos hongos Shiitake en salsa de ostras y verduras.",
    "name": "Vegetarianos"
  },
  "#Cacerola_vegetariana": {
    "titulo": "Cacerola vegetariana",
    "img": cacerovege,
    "precio": "Q73.00",
    "tipo": "Vegetarianos",
    "categoria": "Platos Fuertes",
    "path": "#Cacerola_vegetariana",
    "tiempo": "30-45 min",
    "descripcion": "Deliciosa cacerola con apio, brócoli, arveja china, retoño de bambú, queso de soya, champiñones, hongo Sin Ku y hongos Shiitake, en una deliciosa salsa de ostras.",
    "name": "Vegetarianos"
  },
  "#Chop_suey_vegetariano": {
    "titulo": "Chop Suey vegetariano",
    "img": chopvege,
    "precio": "Q79.00",
    "tipo": "Vegetarianos",
    "categoria": "Platos Fuertes",
    "path": "#Chop_suey_vegetariano",
    "tiempo": "30-45 min",
    "descripcion": "Deliciosa combinación de hongos Sin Ku y hongos Shiitake, peroleados con apio, cebolla, champiñones y chile pimiento y retoño de bambú, dándole un toque especial con semilla de marañón.",
    "name": "Vegetarianos"
  },
  "#Chaw_Mein_Monasterio_Chino": {
    "titulo": "Chaw Mein monasterio chino",
    "img": chawmeinvege,
    "precio": "Q75.00",
    "tipo": "Vegetarianos",
    "categoria": "Platos Fuertes",
    "path": "#Chaw_Mein_Monasterio_Chino",
    "tiempo": "30-45 min",
    "descripcion": "¡Delicioso y jugoso! Fideos artesanales mezclados con apio, zanahoria, brócoli, arveja china, hongos chinos, hongos Sin Ku, champiñones y retoño de bambú, en nuestra exquisita salsa de ostras de la casa.",
    "name": "Vegetarianos"
  },
  "#Verduras_mixtas_con_salsa_de_ostras": {
    "titulo": "Verduras chinas con salsa de ostras.",
    "img": verduras,
    "precio": "Q52.00",
    "tipo": "Vegetarianos",
    "categoria": "Platos Fuertes",
    "path": "#Verduras_mixtas_con_salsa_de_ostras",
    "tiempo": "30-45 min",
    "descripcion": "Frescas verduras según disponibilidad (Kailan, Bok Choy) en una deliciosa salsa de ostras.",
    "name": "Vegetarianos"
  },
  "#Verduras_mixtas_con_salsa_de_ostrassegundo": {
    "titulo": "Verduras mixtas con salsa de ostras",
    "img": verdurasmixtas,
    "precio": "Q42.00",
    "tipo": "Vegetarianos",
    "categoria": "Platos Fuertes",
    "path": "#Verduras_mixtas_con_salsa_de_ostrassegundo",
    "tiempo": "30-45 min",
    "descripcion": "Frescas verduras (brócoli, arveja china y apio) en nuestra deliciosa salsa de ostras.",
    "name": "Vegetarianos"
  },


  //dim sum



  "#Ha_Kao_Dumpling_de_camaron": {
    "titulo": "Ha Kao/Dumpling de camarón",
    "img": duplingCa,
    "precio": "Q32.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Especial masa de camarón envuelta en una tela de harina de arroz, cocido al vapor.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Ha_Kao_Dumpling_de_camaron",
    "name": "Dim Sum"
  },
  "#Pai_Cuat_Costillas_al_vapor": {
    "titulo": "Pai Cuat/ Costillas al vapor",
    "img": Costillasvapor,
    "precio": "Q20.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Deliciosas costillas de cerdo al vapor.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Pai_Cuat_Costillas_al_vapor",
    "name": "Dim Sum"
  },
  "#Fong_Chau_Patitas_de_pollo": {
    "titulo": "Fong Chau/Patitas de pollo",
    "img": Pataspollo,
    "precio": "Q20.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Deliciosas patitas de pollo fritas, después guisadas en una salsa especial a base de salsa de ostras y salsa Chee Hou. Chee Hou: salsa con base de soya, ajo y jengibre.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Fong_Chau_Patitas_de_pollo",
    "name": "Dim Sum"
  },
  

  "#Siu_Mai_Albondigas": {
    "titulo": "Siu Mai/ Albóndigas de cerdo y camarón",
    "img": Albondigascerdo,
    "precio": "Q25.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Deliciosa mezcla de carne de cerdo con camarón envueltas en una fina tela de harina de trigo cocidos al vapor.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Siu_Mai_Albondigas",
    "name": "Dim Sum"
  },
  "#Kao_Choi_Cao_Empanada_de_cebollin": {
    "titulo": "Kao Choi Cao/ Empanada de cebollín",
    "img": EmpanadaCebollin,
    "precio": "Q20.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Tela de harina trigo rellena de carne de cerdo molida y cebollín al vapor.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Kao_Choi_Cao_Empanada_de_cebollin",
    "name": "Dim Sum"
  },
  "#Gnao_Yok_Yun_Albondiga_de_res": {
    "titulo": "Gnao Yok Yun/ Albóndiga de res",
    "img": AlbondigaRes,
    "precio": "20.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Deliciosa albondiga de carne molida de res hecha con el secreto de la cocina china, cocidas al vapor.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Gnao_Yok_Yun_Albondiga_de_res",
    "name": "Dim Sum"
  },
  "#Cha_Siu_Bao_Boll_de_cerdo": {
    "titulo": "Cha Siu Bao/ Bollo de cerdo",
    "img": BolloCerdo,
    "precio": "Q22.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Delicioso bollo de harina de trigo al vapor, relleno de Cha Siu (cerdo en salsa barbacoa).",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Cha_Siu_Bao_Boll_de_cerdo",
    "name": "Dim Sum"
  },
  "#San_Yok_Bao_Bollo_dorado": {
    "titulo": "San Yok Bao/ Bollo dorado",
    "img": Bollodorado,
    "precio": "Q22.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Bollo de harina de trigo relleno de masa de cerdo, cocido al vapor y luego dorado por encima.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#San_Yok_Bao_Bollo_dorado",
    "name": "Dim Sum"
  },
  "#Nai_Wong_Bao_Bollo_de_manjar": {
    "titulo": "Nai Wong Bao/ Bollo de manjar",
    "img": BolloManjar,
    "precio": "Q22.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Bollo de harina de trigo relleno de un exquisito manjar chino al vapor.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Nai_Wong_Bao_Bollo_de_manjar",
    "name": "Dim Sum"
  },
  "#Wou_Tip_Dumpling_frito": {
    "titulo": "Wou Tip/ Dumpling frito",
    "img": Dumplingfrito,
    "precio": "Q40.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Deliciosa empanada frita de harina de trigo rellena de cerdo y cebollín.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Wou_Tip_Dumpling_frito",
    "name": "Dim Sum"
  },
  "#Tamal_chino_de_arroz_especial": {
    "titulo": "Tamal chino de arroz especial",
    "img": Tamalchino,
    "precio": "Q38.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Arroz glutinoso chino, Cha Siu, pollo, castaña de agua, hongos chinos, envueltos en una hoja especial de China.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Tamal_chino_de_arroz_especial",
    "name": "Dim Sum"
  },
  "#Gnao_Yok_Tou_Panza_de_res": {
    "titulo": "Gnao Yok Tou/ Panza de res",
    "img": Panzares,
    "precio": "Q20.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Panza de res, condimentada con salsa de ostras y salsa Chee Hou, levemente picante. Chee Hou: salsa con base de soya, ajo y jengibre.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Gnao_Yok_Tou_Panza_de_res",
    "name": "Dim Sum"
  },
  "#Gnao_Yok_Cheon_Rollos_con_Lomito": {
    "titulo": "Gnao Yok Cheon/ Rollos con Lomito",
    "img": Rolloslomito,
    "precio": "Q20.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Deliciosos enrollados de harina de arroz al vapor,rellenos de lomito bañados con salsa de soya.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Gnao_Yok_Cheon_Rollos_con_Lomito",
    "name": "Dim Sum"
  },
  "#Cha_Siu_Cheon_Rollos_con_Cha_Siu": {
    "titulo": "Cha Siu Cheon/ Rollos con Cha Siu",
    "img": ChaSiuCheon,
    "precio": "Q20.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Enrollados de harina de arroz al vapor, rellenos de Cha Siu (cerdo), bañados en salsa de soya. ",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Cha_Siu_Cheon_Rollos_con_Cha_Siu",
    "name": "Dim Sum"
  },
  "#Cha_Siu_Sou_Empanada_de_Cha_Siu": {
    "titulo": "Cha Siu Sou/ Empanada de Cha Siu",
    "img": chasisouempanada,
    "precio": "Q20.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Bocadillos de harina de trigo horneados y rellenos de Cha Siu(cerdo en especial salsa de barbacoa).",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Cha_Siu_Sou_Empanada_de_Cha_Siu",
    "name": "Dim Sum"
  },
  "#Ha_Cheon_Rollos_con_camaron": {
    "titulo": "Ha Cheon/ Rollos con camarón",
    "img": HaCheon,
    "precio": "Q32.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Enrollados de harina de arroz al vapor rellenos de camarón, cubiertos con salsa de soya.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Ha_Cheon_Rollos_con_camaron",
    "name": "Dim Sum"
  },
  "#Yu_Pin_Cheon_Rollos_de_pescado": {
    "titulo": "Yu Pin Cheon/ Rollos de pescado",
    "img": YuPin,
    "precio": "Q20.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Enrollados de harina de arroz al vapor rellenos de pescado, cubiertos con salsa de soya.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Yu_Pin_Cheon_Rollos_de_pescado",
    "name": "Dim Sum"
  },
  "#Cheon_Fan_Rollos_simples": {
    "titulo": "Cheon Fan/ Rollos simples",
    "img": Rollossimples,
    "precio": "Q18.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Enrollados de harina de arroz al vapor, cubiertos con salsa de soya.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Cheon_Fan_Rollos_simples",
    "name": "Dim Sum"
  },
  "#Tan_Taat_Tartaleta_de_huevo": {
    "titulo": "Tan Taat/ Tartaleta de huevo",
    "img": TanTaat,
    "precio": "Q22.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Especial tartaleta a base de harina trigo, rellena de una deliciosa crema de yemas de huevo, leche evaporada y azúcar.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Tan_Taat_Tartaleta_de_huevo",
    "name": "Dim Sum"
  },
  "#Costillas_con_Ajo": {
    "titulo": "Costillas con Ajo",
    "img": Costillasconajo,
    "precio": "Q20.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Pequeños trozos de costilla frita con una deliciosa salsa de ajo (contiene maní).",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Costillas_con_Ajo",
    "name": "Dim Sum"
  },
  "#Mataicou_Bocado_de_castana_de_agua": {
    "titulo": "Mataicou/ Bocado de castaña de agua",
    "img": Mataicou,
    "precio": "Q25.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Deliciosos cuadritos hechos a base de harina de castaña de agua, trocitos de castaña de agua y elotes caramelizados en azúcar.  ",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Mataicou_Bocado_de_castana_de_agua",
    "name": "Dim Sum"
  },
  "#Lopacou_Bocado_de_rabano_blanco": {
    "titulo": "Lopacou/ Bocado de rábano blanco",
    "img": Lopacou,
    "precio": "Q20.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Deliciosos trocitos de harina de arroz relleno de nabo blanco y tocineta.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Lopacou_Bocado_de_rabano_blanco",
    "name": "Dim Sum"
  },
  "#Wu_Tao_Cou_Bocado_de_malanga": {
    "titulo": "Wu Tao Cou/ Bocado de malanga",
    "img": Bocadomalanga,
    "precio": "Q20.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Cuadritos de una especial mezcla de harina de arroz, malanga y tocineta.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Wu_Tao_Cou_Bocado_de_malanga",
    "name": "Dim Sum"
  },
  "#Empanada_de_pescado": {
    "titulo": "Empanada de pescado",
    "img": Empanadapescado,
    "precio": "Q20.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Dumpling frito relleno de pescado con cebollín.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Empanada_de_pescado",
    "name": "Dim Sum"
  },
  "#Ham_Soi_Koc_Empanada_frita_de_arroz": {
    "titulo": "Ham Soi Koc/ Empanada frita de arroz",
    "img": HamSoiKoc,
    "precio": "Q20.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Empanada frita hecha de harina de arroz, rellena de pollo, hongos chinos, cha siu y castaña de agua.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Ham_Soi_Koc_Empanada_frita_de_arroz",
    "name": "Dim Sum"
  },
  "#Chun_Tui_Chai_Bolitas_de_ajonjoli": {
    "titulo": "Chun Tui Chai/ Bolitas de ajonjolí",
    "img": BolitasAjonjoli,
    "precio": "Q20.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Bolitas fritas a base de harina de arroz, cubiertas de ajonjolí, rellenas de frijol negro dulce.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Chun_Tui_Chai_Bolitas_de_ajonjoli",
    "name": "Dim Sum"
  },
  "#Sopa_de_arroz_con_variedad_de_carnes": {
    "titulo": "Sopa de arroz con variedad de carnes",
    "img": Sopadecarnes,
    "precio": "43.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Sopa de arroz, pato en tiritas, cerdo en lascas y cebollita picada.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Sopa_de_arroz_con_variedad_de_carnes",
    "name": "Dim Sum"
  },
  "#Sopa_de_arroz_con_marrano_y_huevo_peitan": {
    "titulo": "Sopa de arroz con marrano y huevo peitan",
    "img": Sopaconmarrano,
    "precio": "43.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Deliciosa sopa de arroz con cerdo en lascas, huevo peitan negro y cebollita picada.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Sopa_de_arroz_con_marrano_y_huevo_peitan",
    "name": "Dim Sum"
  },
  "#Sopa_de_arroz_con_pescado": {
    "titulo": "Sopa de arroz con pescado",
    "img": Sopapescado,
    "precio": "43.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Sopa de arroz con pescado en lascas y cilantro, acompañado de fideo de arroz y tela de wantan en tiritas fritas.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Sopa_de_arroz_con_pescado",
    "name": "Dim Sum"
  },
  "#Sopa_de_Arroz_con_Lomito_y_Lechuga": {
    "titulo": "Sopa de arroz con lomito y lechuga",
    "img": Sopalomito,
    "precio": "43.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Sopa de arroz, lomito y lechuga, a la cual se le deja caer un huevo, que se cocina al momento de tener contacto con lo caliente de la sopa.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Sopa_de_Arroz_con_Lomito_y_Lechuga",
    "name": "Dim Sum"
  },
  "#Sopa_de_arroz_con_lomito_y_Choi_Sam": {
    "titulo": "Sopa de arroz con lomito y Choi Sam",
    "img": Sopachoisam,
    "precio": "30.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Especial sopa de arroz con lomito y verdura china de temporada.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Sopa_de_arroz_con_lomito_y_Choi_Sam",
    "name": "Dim Sum"
  },
  "#Sopa_de_arroz_con_lechuga": {
    "titulo": "Sopa de arroz con lechuga",
    "img": Sopalechuga,
    "precio": "30.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Especial sopa de arroz con lechuga.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Sopa_de_arroz_con_lechuga",
    "name": "Dim Sum"
  },
  "#Chaw_Mein_con_salsa_especial": {
    "titulo": "Chaw Mein con salsa especial",
    "img": ChaoMeinespecial,
    "precio": "42.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Especial fideo al huevo mezclado con cebolla, chile pimiento, apio, salsa de soya oscura, salteado al perol.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Chaw_Mein_con_salsa_especial",
    "name": "Dim Sum"
  },
  "#Fideos_con_jengibre_y_cebollitas": {
    "titulo": "Fideos con jengibre y cebollitas",
    "img": FideoJengibre,
    "precio": "42.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Fideos de harina al huevo con jengibre, salsa de ostras  y cebollita.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Fideos_con_jengibre_y_cebollitas",
    "name": "Dim Sum"
  },
  "#Fideo_de_Arroz_delgado_con_Cha_Siu": {
    "titulo": "Fideo de arroz delgado con Cha Siu",
    "img": FideoChaSiu,
    "precio": "57.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Fideo de arroz delgado revuelto con tiritas de Cha Siu y curitido chino (nabo, jengibre, chile jalapeño,  chile pimiento y zanahoria). Todo salteado al perol, con notas levemente ácidas y seco.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Fideo_de_Arroz_delgado_con_Cha_Siu",
    "name": "Dim Sum"
  },
  "#Bocado_de_Rabano_Blanco_con_Salsa_XO": {
    "titulo": "Bocado de Rábano Blanco con Salsa XO",
    "img": BocadosalsaXO,
    "precio": "30.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Exquisitos cuadritos de masa de harina de arroz relleno de nabo blanco y tocineta, peroleados en una especial salsa XO (deliciosa mezcla de jamón serrano, camaroncillo, anchoas, carne de cerdo, jalapeño y cebollita).",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Bocado_de_Rabano_Blanco_con_Salsa_XO",
    "name": "Dim Sum"
  },
  "#Calamares_fritos": {
    "titulo": "Calamares fritos",
    "img": Calamaresfritos,
    "precio": "25.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Exquisitos calamares fritos, envueltos en una deliciosa combinación de sabores (contiene mantequilla de maní), empanizado con panko.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Calamares_fritos",
    "name": "Dim Sum"
  },
  "#Chan_Bao_Bollo_horneado_con_Cha_Siu": {
    "titulo": "Chan Bao/ Bollo horneado con Cha Siu",
    "img": ChanBaohorneado,
    "precio": "22.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Delicioso bollo de harina trigo relleno de Cha Siu (cerdo con salsa barbacoa) cubierto con un toque de miel.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Chan_Bao_Bollo_horneado_con_Cha_Siu",
    "name": "Dim Sum"
  },
  "#Nuo_Mai_Chi_Bolitas_de_manjar": {
    "titulo": "Nuo Mai Chi/ Bolitas de manjar",
    "img": NuoMaiChi,
    "precio": "20.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Bolitas a base de harina de arroz, rellenas de especial manjar, cubiertas con coco rallado, cocidas al vapor.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Nuo_Mai_Chi_Bolitas_de_manjar",
    "name": "Dim Sum"
  },
  "#Churros_Chinos": {
    "titulo": "Churros Chinos",
    "img": Churroschinos,
    "precio": "18.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Churro a base de harina y frito, perfecto para acompañar las sopas de arroz.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Churros_Chinos",
    "name": "Dim Sum"
  },
  "#Po_Lo_Bao_Bollo_de_pina": {
    "titulo": "Po Lo Bao/ Bollo de piña",
    "img": PoLoBao,
    "precio": "22.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Delicioso pan de trigo dulce horneado,  relleno de manjar con  piña.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Po_Lo_Bao_Bollo_de_pina",
    "name": "Dim Sum"
  },
  "#Taquitos_de_banano": {
    "titulo": "Taquitos de banano",
    "img": Taquitosbanano,
    "precio": "22.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Exquisitos taquitos rellenos de banano y leche condensada.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Taquitos_de_banano",
    "name": "Dim Sum"
  },
  "#Lao_Sao_Bao_Bao_de_huevo_salado": {
    "titulo": "Lao Sao Bao/ Bao de huevo salado",
    "img": LaoSaPaoHuevoSalado,
    "precio": "22.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Bollo de harina de trigo al vapor, relleno de huevo salado y azúcar.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Lao_Sao_Bao_Bao_de_huevo_salado",
    "name": "Dim Sum"
  },
  "#Ye_Chi_Taat_Tartaleta_de_coco": {
    "titulo": "Ye Chi Taat/ Tartaleta de coco",
    "img": TartaletaCoco,
    "precio": "22.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Tartaleta de harina de trigo rellena de masa de coco,  huevo y azúcar.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Ye_Chi_Taat_Tartaleta_de_coco",
    "name": "Dim Sum"
  },
  "#Jalapeno_relleno_de_pescado": {
    "titulo": "Jalapeño relleno de pescado",
    "img": Jalapeñopescado,
    "precio": "20.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Chiles jalapeños dorados rellenos de  masa de pescado.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Jalapeno_relleno_de_pescado",
    "name": "Dim Sum"
  },
  "#Ye_Chi_Bao_Bollo_de_Coco": {
    "titulo": "Ye Chi Bao/ Bollo de Coco",
    "img": YeChiBao,
    "precio": "18.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Bollo de harina de trigo horneado relleno de coco con huevo con manjar de huevo salado.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Ye_Chi_Bao_Bollo_de_Coco",
    "name": "Dim Sum"
  },
  "#Tao_Sa_Bao_Bollo_de_frijol": {
    "titulo": "Tao Sa Bao/ Bollo de frijol",
    "img": TaoSaBaofrijol,
    "precio": "22.00",
    "tipo": "Dim Sum",
    "categoria": "Dim Sum",
    "descripcion": "Suave bollo de harina, relleno de frijol dulce al vapor.",
    //no tenemos tiempos
    "tiempo": "30-45 min",
    "path": "#Tao_Sa_Bao_Bollo_de_frijol",
    "name": "Dim Sum"
  },
}

export { platos };