import React from 'react';
import styles from './menudetail.module.css'
//import menu1 from '../../assets/menu/1.jpg';
import button from '../../assets/detailmenu/boton-100-min.jpg'
//import icon from '../../assets/menu/1.jpg';

import iconplate from '../../assets/detailmenu/plate-min.png';
import iconprice from '../../assets/detailmenu/Q_icon-.png';
import icontime from '../../assets/detailmenu/clock-min.png';
import iconFB from '../../assets/detailmenu/Messenger-min.png';
import iconWapp from '../../assets/detailmenu/whatsapp-min.png';
import iconphone from '../../assets/detailmenu/phone-min.png';
import iconshare from '../../assets/detailmenu/share-min.png';


import { platos } from '../MenuList/menu';

class Menudetail extends React.Component {
  render() {
    const {
      location
    } = this.props;
    return (
      <div className={`row ${styles.background} `}>
        <div className="col-sm-12 d-none d-sm-block d-lg-block">
          <button onClick={this.props.history.goBack} className={styles.buttonback}>
            <div>Regresar</div>
          </button>
        </div>
        <div className={`col col-sm col-lg ${styles.mobile}`} >
          <div className={`row `}>
            <div className={`col col-sm col-lg ${styles.rowimg}`} >
              <img src={platos[location.hash].img} alt="logo" />
            </div>
          </div>
          <div className={`row  `}>
            <div className={`col col-sm col-lg   ${styles.rowtitle}`} >
              <p className={styles.texttitle}>{platos[location.hash].titulo}</p>
              <p className={styles.textsubtitle}>{platos[location.hash].name}</p>
            </div>
            <div className={`col col-sm col-lg ${styles.buttonshare}`} >
              <a href="/home">
                <img src={iconshare} alt="logo" />
              </a>
            </div>
          </div>
          <div className={`row `}>
            <div className={`col col-sm col-lg   ${styles.rowdetailimg}`} >
              <img src={iconplate} alt="logo" />
            </div>
            <div className={`col col-sm col-lg   ${styles.rowdetail}`} >
              <p className={styles.textdetailtitle}>Tipo de comida</p>
              <p className={styles.textdetailinfo}>{platos[location.hash].categoria}</p>
            </div>
            <div className={`col col-sm col-lg   ${styles.rowdetailimg}`} >
              <img src={iconprice} alt="logo" />
            </div>
            <div className={`col col-sm col-lg ${styles.rowdetail}`} >
              <p className={styles.textdetailtitle}>Precio del Platillo</p>
              <p className={styles.textdetailinfo}>{platos[location.hash].precio}</p>
            </div>
            <div className={`col col-sm col-lg   ${styles.rowdetailimg}`} >
              <img src={icontime} alt="logo" />
            </div>
            <div className={`col col-sm col-lg ${styles.rowdetail}`} >
              <p className={styles.textdetailtitle}>Tiempo de Entrega</p>
              <p className={styles.textdetailinfo}>{platos[location.hash].tiempo}</p>
            </div>
          </div>
          <div className={`row `}>
            <div className={`col col-sm col-lg ${styles.rowtextingredients}`} >
              <p className={styles.textingredients}>Ingredientes</p>
            </div>
          </div>
          <div className={`row `}>
            <div className={`col col-sm col-lg ${styles.rowtext}`} >
              <p className={styles.rowtextparraf}>
                {platos[location.hash].descripcion}</p>
              {
                platos[location.hash].sauce &&
                Object.values(platos[location.hash].sauce).map((variant, index) => (
                  <div className="row" key={index}>
                    <div className="col-12">
                      <ul className={styles.iconPdlatos}>
                        <li>
                          {platos[location.hash].sauce[index]}
                        </li>
                      </ul>
                    </div>

                  </div>
                ))
              }
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {
                platos[location.hash].variants && platos[location.hash].imgs && platos[location.hash].descs &&
                Object.values(platos[location.hash].variants).map((variant, index) => (
                  <div className="row" key={index}>
                    <div className="col-3">
                      <div className={styles.iconPlatos}>
                        <img src={platos[location.hash].imgs[index]} alt="Chicken" />
                      </div>
                    </div>
                    <div className="col-9">
                      <p> {platos[location.hash].descs[index]}</p>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
          <div className="row">
            <div className={`col-8 col-sm col-lg  ${styles.rowbdsutton}`} >
              <p className={styles.textdetailtitlemobile}>Servicio a domicilio minimo de Q60.00</p>
            </div>
            <div className={`col-12 ${styles.rowbutton}`} >
              <ul className={styles.iconPdlatos}>
                <li>
                  Menú sujeto a disponibilidad
                </li>
                {
                    platos[location.hash].categoria === 'Dim Sum'&&(
                    <li>
                      Únicamente en sucursal de Montúfar
                    </li>
                    )
                    }
              </ul>
            </div>
          </div>
          <div className={`row align-items-end`}>
            <div className={`col col-sm col-lg  ${styles.rowbutton}`} >
              <img src={button} alt="logo" />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className={`col-2  ${styles.iconredes}`} >
              <div className={styles.imgicon}>
                <a href="https://www.facebook.com/messages/t/RestauranteLaiLai">
                  <img src={iconFB} alt="logo" />
                </a>
              </div>
            </div>
            <div className={`col-2   ${styles.iconredeswa}`} >
              <div className={styles.imgicon}>
                <a href="https://api.whatsapp.com/send?phone=50247931733">
                  <img src={iconWapp} alt="logo" />
                </a>
              </div>
            </div>
            <div className={`col-2   ${styles.iconredesphone}`} >
              <div className={styles.imgicon}>
                <a href="tel:1733">
                  <img src={iconphone} alt="logo" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={`col col-sm col-lg ${styles.desktop}`} >
          <div className={`row `}>
            <div className={`col col-sm col-lg ${styles.rowimg}`} >
              <div className={styles.imgmenu}>
                <img src={platos[location.hash].img} alt="logo" />
              </div>
            </div>
          </div>
          <div className={`row  ${styles.row}`}>

            <div className={`col col-sm col-lg   ${styles.rowstitle}`} >
              <div className="row">
                <div className={`col col-sm col-lg   ${styles.rowtitle}`} >
                  <p className={styles.texttitle}>{platos[location.hash].titulo}</p>
                  <p className={styles.textsubtitletop}>{platos[location.hash].name}</p>
                </div>
              </div>
              <div className="row">
                <div className={`col col-sm col-lg   ${styles.rowtitlemiddle}`} >
                  <div className="row">
                    <div className={`col col-sm col-lg   ${styles.rowdetailimg}`} >
                      <div className={styles.imgiconmenu}>
                        <img src={iconplate} alt="logo" />
                      </div>
                    </div>
                    <div className={`col col-sm col-lg   ${styles.rowdetail}`} >
                      <p className={styles.textdetailtitle}>Tipo de comida</p>
                      <p className={styles.textdetailinfo}>{platos[location.hash].categoria}</p>
                    </div>
                    <div className={`col col-sm col-lg   ${styles.rowdetailimg}`} >
                      <div className={styles.imgiconmenu}>
                        <img src={icontime} alt="logo" />
                      </div>
                    </div>
                    <div className={`col col-sm col-lg   ${styles.rowdetail}`} >
                      <p className={styles.textdetailtitle}>Tiempo de Entrega</p>
                      <p className={styles.textdetailinfo}>{platos[location.hash].tiempo}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className={`col col-sm col-lg   ${styles.rowtitle}`} >
                  <p className={styles.texttitle}>Ingredientes</p>
                  <p className={styles.textsubtitle}>{platos[location.hash].descripcion}</p>
                  {
                    platos[location.hash].sauce &&
                    Object.values(platos[location.hash].sauce).map((variant, index) => (
                      <div className="row" key={index}>
                        <div className="col-12">
                          <ul className={styles.iconPdlatos}>
                            <li>
                              {platos[location.hash].sauce[index]}
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
            <div className={`col col-sm col-lg   ${styles.rowtitleright}`} >
              <div className="row">
                <div className={`col col-sm col-lg   ${styles.rowdetailimg}`} >
                  <div className={styles.imgiconmenu}>
                    <img src={iconprice} alt="logo" />
                  </div>
                </div>
                <div className={`col col-sm col-lg   ${styles.rowdetailprecio}`} >
                  <p className={styles.textdetailinfo}>{platos[location.hash].precio}</p>
                </div>
                <div className={`col col-sm col-lg   ${styles.rowdetailtextprecio}`} >
                  <p className={styles.textdetailtitle}>Precio del Platillo</p>
                </div>
              </div>
              <div className={`row `}>
                <div className={`col-12 ${styles.rowbutton}`} >
                  <p className={styles.textdetailtitle}>Servicio a domicilio minimo de Q60.00</p>
                </div>
                <div className={`col-12 ${styles.rowbutton}`} >
                  <ul className={styles.iconPdlatos}>
                    <li>
                      Menú sujeto a disponibilidad
                    </li>{
                    platos[location.hash].categoria === 'Dim Sum'&&(
                    <li>
                      Únicamente en sucursal de Montúfar
                    </li>
                    )
                    }
                  </ul>
                </div>

                <div className="col-12">
                  {
                    platos[location.hash].variants && platos[location.hash].imgs && platos[location.hash].descs &&
                    Object.values(platos[location.hash].variants).map((variant, index) => (
                      <div className="row" key={index}>
                        <div className="col-sm-3 col-lg-2">
                          <div className={styles.iconPlatos}>
                            <img src={platos[location.hash].imgs[index]} alt="Chicken" />
                          </div>
                        </div>
                        <div className="col-sm-9 col-lg-9">
                          <p> {platos[location.hash].descs[index]}</p>

                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
              <div className={`row `}>
                <div className={`col col-sm col-lg  ${styles.rowbutton}`} >
                  <p className={styles.textdetailtitle}>¿Deseas realizar tu pedido?</p>
                </div>
              </div>
              <div className={`row `}>
                <div className={`col col-sm col-lg  ${styles.rowbutton}`} >
                  <img src={button} alt="logo" />
                </div>
              </div>
              <div className="row">
                <div className={`col col-sm col-lg   ${styles.iconredes}`} >
                  <div className={styles.imgicon}>
                    <a href="https://www.facebook.com/messages/t/RestauranteLaiLai">
                      <img src={iconFB} alt="logo" />
                    </a>
                  </div>
                </div>
                <div className={`col col-sm col-lg   ${styles.iconredeswa}`} >
                  <div className={styles.imgicon}>
                    <a href="https://api.whatsapp.com/send?phone=50247931733">
                      <img src={iconWapp} alt="logo" />
                    </a>
                  </div>
                </div>
                <div className={`col col-sm col-lg   ${styles.iconredesphone}`} >
                  <div className={styles.imgicon}>
                    <a href="tel:1733">
                      <img src={iconphone} alt="logo" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Menudetail;

