import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';

import ReactGA from 'react-ga';

import { hotjar } from 'react-hotjar';
import ReactPixel from 'react-facebook-pixel';


import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'GTM-NJWJWKP'
}
 
TagManager.initialize(tagManagerArgs)



hotjar.initialize(1958852, 6);

const advancedMatching = { em: 'acortes@devpackgroup.com', };
const options = {
  autoConfig: true,
  debug: false,
};
ReactPixel.init('754398838762177', advancedMatching, options);
ReactPixel.pageView();

const trackingId = "UA-164986121-2";
ReactGA.initialize(trackingId);
const history = createBrowserHistory();

history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
