
const direcciones = {
  1: {
    "direccion": "12 calle 5-27 zona 9",
    "nombre": "Lai Lai Montúfar",
    "lat": "14.601947",
    "lng": "-90.5213706"
  },
  2: {
    "nombre": "Lai Lai Chimaltenango",
    "direccion": "Diagonal 1, 1-101 Carretera Interamericana, Centro Comercial Paseo Andaria, Local 59, zona 8, Chimaltenango",
    "lat": "14.556157",
    "lng": "-90.4549983"
  },
  3: {
    "nombre": "Lai Lai Eskala",
    "direccion": "Km. 13.8 Calzada Roosevelt zona 3 Mixco local Lfc-04 C.C. Eskala",
    "lat": "14.658041274429612",
    "lng": "-90.8305422288351", 
  },
  4: {
    "nombre": "Lai Lai Peri Roosevelt",
    "direccion": "Calzada Roosevelt 25-50 zona 7, C.C. Peri Roosevelt foodcour",
    "lat": "14.6271525",
    "lng": "-90.5554179"
  },
  5: {
    "nombre": "Lai Lai San Lucas",
    "direccion": "Km. 29.5 Carretera Interamericana CA-1 C.C. Las Puertas Local C-105",
    "lat": "14.6094667",
    "lng": "-90.6588241"
  },
  6: {
    "nombre": "Lai Lai San Sebastián",
    "direccion": "Km. 14.1 carretera a El Salvador Antigua finca Las Victorias zona 8, Aldea Puerta Parada, C.C. Paseo San Sebastian, local 1 y 92a Santa Catarina Pinula",
    "lat": "14.5635334",
    "lng": "-90.4642895"
  },
  7: {
    "nombre": "Lai Lai Xela 1",
    "direccion": "Km. 205 Carretera a San Marcos C.C. Interplaza Xela, local Free Stand 10, La Esperanza, Quetzaltenango",
    "lat": "14.8620652",
    "lng": "-91.5518625"
  },
  8: {
    "nombre": "Lai Lai Majadas",
    "direccion": "27 avenida 6-50 zona 11",
    "lat": "14.6221598",
    "lng": "-90.5696937"
  },
  9: {
    "nombre": "Lai Lai Sankris Mall",
    "direccion": "3 Calle Sector A-3 Blvd. San Cristóbal 6-72 Zona 8 mixco C.C. Sankris Mall Local C-105",
    "lat": "14.6112874",
    "lng": "-90.5980559"
  },
  10: {
    "nombre": "Lai Lai Plaza Madero",
    "direccion": "Km. 21.7 Carretera a El Salvador, Centro Comercial Plaza Madero, Local 12 y 13, Villa Canales, Guatemala",
    "lat": "14.503867029946386",
    "lng": "-90.48155191534039" 
  },
  11: {
    "nombre": "Lai Lai Metronorte",
    "direccion": "Carretera CA-9 Norte ruta al Atlántico 10-44 zona 17 C.C. Metronorte local 2-827",
    "lat": "14.6467916",
    "lng": "-90.4766136"
  },
  12: {
    "nombre": "Lai Lai Naranjo Mall",
    "direccion": "23 calle 10-00 zona 4 Condado Naranjo, Mixco.  C.C. Naranjo Mall 1er nivel local L-136",
    "lat": "14.6511229",
    "lng": "-90.54278"
  },
  13: {
    "nombre": "Lai Lai Metronorte Food Court",
    "direccion": "Km. 5 carretera al Atlántico 10-44 zona 17, C.C. Metronorte local 411",
    "lat": "14.6475134",
    "lng": "-90.4785488"
  },
  14: {
    "nombre": "Lai Lai Xela 2",
    "direccion": "Km. 205 Carretera a San Marcos C.C. Interplaza Xela, Interior Food Court local Ca La Esperanza, Quetzaltenango.",
    "lat": "14.8628785",
    "lng": "-91.5506019"
  },
  15: {
    "nombre": "Lai Lai Walmart Roosevelt",
    "direccion": "Calzada Roosevelt 26-95 Zona 11 Walmart Roosevelt (Interior Food Court) Local 9",
    "lat": "14.62565",
    "lng": "-90.5578353"
  },
  16: {
    "nombre": "Lai Lai Próceres",
    "direccion": "16 calle 2-00 zona 10 C.C. Los Próceres, 2do nivel local B-201",
    "lat": "14.5943149",
    "lng": "-90.5156002"
  },
  17: {
    "nombre": "Lai Lai Pradera Concepción",
    "direccion": "Km. 15.5 carretera a El Salvador C.C. Pradera Concepción 3er nivel local 346 Santa Catarina Pinula",
    "lat": "14.5512875",
    "lng": "-90.4535416"
  },
  18: {
    "nombre": "Lai Lai Interplaza Escuintla",
    "direccion": "Km. 60 autopista Escuintla a Palín, Escuintla, C.C. Interplaza Escuintla local Free Stand FS-3",
    "lat": "14.2941218",
    "lng": "-90.7991618"
  },
  19: {
    "nombre": "Lai Lai Alamos",
    "direccion": "0 Calle 0-60 zona 7 Villa Hermosa 1, Ec C.C. Centro Los Alamos local 14, san Miguel Petapa",
    "lat": "14.5242132",
    "lng": "-90.5351445"
  },
  20: {
    "nombre": "Lai Lai Metrocentro",
    "direccion": "0 Calle 16-20 zona 4 C.C. Metrocentro Villa Nueva Local L119-A",
    "lat": "14.5133521",
    "lng": "-90.5776514"
  },
  21: {
    "nombre": "Lai Lai Amatiltán",
    "direccion": "Km. 29.6 caretera al pacífico CA-9 Sur, sector 5 área de convivencia, C.C. Flores del Lago, Amatitlán",
    "lat": "14.4695787",
    "lng": "-90.638079"
  },
  22: {
    "nombre": "Lai Lai Pradera Escuintla",
    "direccion": "1 Avenida 10-40 Zona 3, Escuintla, Centro Comercial Pradera Escuintla local 76",
    "lat": "14.2900963",
    "lng": "-90.785265"
  },
  23: {
    "nombre": "Lai Lai Pradera Vistares",
    "direccion": "Diagonal Diecinueve, 36-01 Zona 12, Centro Comercial, La Pradera Vistares, Primer Nivel, Local L-101 Guatemala, Guatemala",
    "lat": "14.5829987",
    "lng": "-90.5435957"
  },
  24: {
    "nombre": "CC Miraflores",
    "direccion": "21 avenida 4-32, Local FC-3 Centro Comercial Miraflores, zona 11",
    "lat": "14.6202954",
    "lng": "-90.5537401"
  },
  25: {
    "nombre": "Condado Naranjo",
    "direccion": "21 Calle 7-22 z4 Mixco, Condado Naranjo",
    "lat": "14.654088020324707",
    "lng": "-90.54452514648438"
  },
  26: {
    "nombre": "Portales",
    "direccion": "CA-9 Norte ruta a la carretera al Atlántico 3-20, Centro Comercial Portales, Locales L116A y L117, zona 17, Guatemala, Guatemala",
    "lat": "14.648475803251964",
    "lng": "-90.4821599423298", 
  },
  27: {
    "nombre": "Lai Lai Jutiapa",
    "direccion": "Km 116 Carretera Interamericana, Centro Comercial Metroplaza Jutiapa, Locales 408. 409, 411 y 412 Jutiapa, Jutiapa",
    "lat": "14.2925427",
    "lng": "-89.9102086", 
  },
  28: {
    "nombre": "Lai Lai 4 caminos",
    "direccion": "Barrio La Ciénaga zona 7 Centro Comercial Metroplaza 4 Caminos Locales 125, 126, 142 y 143, San Cristobal Totonicapán, Totonicapán",
    "lat": "14.2925427",
    "lng": "-89.9102086", 
  },
  29: {
    "nombre": "Lai Lai Chimaltenango Interplaza",
    "direccion": "Kilómetro 50.4 Carretera a El Libramiento, Centro Comercial Interplaza Chimaltenango, Local Freestanding No. 14.  El Tejar, Chimaltenango",
    "lat": "14.6373770",
    "lng": " -90.7843850", 
  },
  30: {
    "nombre": "Lai Lai Oakland Mall",
    "direccion": "Diagonal 6 12-51 Zona 10, Colonia Las Margaritas, Centro Comercial Oakland Mall, Cuarto Nivel, Local FC-406",
    "lat": "14.5987057",
    "lng": "-90.5101812", 
  },
  31: {
    "nombre": "Lai Lai Escuintla Interplaza Food Court",
    "direccion": "Km. 60 autopista Escuintla a Palín, Escuintla, C.C. Interplaza Escuintla.  Food Court.",
    "lat": "14.2946796",
    "lng": "-90.8005914", 
  }

  
}

export { direcciones };