const postres ={
  1:{
    "name": "Porción de Machay",
    "price": "Q6.00",
    "tipo": "machay"
  },
  2:{
    "name": "Caja de Machay (10 unidades)",
    "price": "Q35.00 ",
    "tipo": "machay"
  },
  3:{
    "name": "Caja de Machay (16 unidades)",
    "price": "Q70.00 ",
    "tipo": "machay"
  },
  4:{
    "name": "Galletas Chinas (6 unidades)",
    "price": "Q12.00 ",
    "tipo": "galleta"
  },
  5:{
    "name": "Galletas Chinas (12 unidades)",
    "price": "Q20.00 ",
    "tipo": "galleta"
  },
  
  6:{
    "name": "Galleta de Matcha",
    "price": "Q12.00",
    "tipo": "galleta"
  },
  7:{
    "name": "Galleta de Chispas de chocolate y ajonjolí negro",
    "price": "Q10.00",
    "tipo": "galleta"
  },
  8:{
    "name": "Lychee",
    "price": "Q10.00 ",
    "tipo": "lychee"
  },
  9:{
    "name": "Longan",
    "price": "Q43.00 ",
    "tipo": "longan"
  },
  10:{
    "name": "Wamuy",
    "price": "Q14.00 ",
    "tipo": "wamuy"
  },
  11:{
    "name": "Corbatas dulces",
    "price": "Q12.00",
    "tipo":"corbatas"
  }
}
export {postres};