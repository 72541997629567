


const refrescos = {
  1:{
    "name": "Gaseosas Latas",
    "price": "Q16.00",
    "tankard": ""
  },
  2:{
    "name": "Limonada, Naranjada o Jamaica",
    "price": "Q16.00",
    "tankard": "Q61.00"
  },
  3:{
    "name": "Limonada, Naranjada o Jamaica (Doble)",
    "price": "Q28.00",
    "tankard": ""
  },
  4:{
    "name": "Té Frío",
    "price": "Q16.00",
    "tankard": "Q61.00"
  },
  5:{
    "name": "Jugo de Naranja",
    "price": "Q12.00",
    "tankard": "Q72.00"
  },
  6:{
    "name": "Jugo de tomate preparado",
    "price": "Q12.00",
    "tankard": ""
  },
  7:{
    "name": "Jugo (Piña, Manzana, Pera o Melocotón)",
    "price": "Q12.00",
    "tankard": ""
  },
  8:{
    "name": "Bebida Rehidratante",
    "price": "Q14.00",
    "tankard": ""
  },
  9:{
    "name": "Café Frío",
    "price": "Q16.00",
    "tankard": ""
  },
  10:{
    "name": "Agua Pura Embotellada",
    "price": "Q8.00",
    "tankard": ""
  },
  11:{
    "name": "Fruit Punch",
    "price": "Q25.00",
    "tankard": "Q75.00"
  },
}

export {refrescos};